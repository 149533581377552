
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';
import AddNoteModal from './AddNoteModal';
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NationalScienceFoundation from './NationalScienceFoundation';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import { Speak, CreateFeedItem, CreateFeedAPI } from './Functions';
import DesktopNavbar from './DesktopNavbar';
import EnvironmentMobile from './EnvironmentMobile';
import GenerateFeed from './GenerateFeed';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


 



//https://evening-plains-21303.herokuapp.com/
function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <Card>{props.value}</Card>;
}
function createFullBillFeed() {

  function NumberList(props) {
    const numbers = props.numbers;
    const listItems = numbers.map((number) =>
      // Correct! Key should be specified inside the array.
      <ListItem key={number.toString()} value={number} />
    );
    return (

      <Container><Card>{listItems}</Card></Container>

    );
  }
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const linkChunk = links.toString().substring(36);
      //console.log(billTitle[99])
      const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
      //console.log(viewBillLink)


      ReactDOM.render(
        <NumberList numbers={billTitle} />,
        document.getElementById('root')
      );

      //render(<BillFeed />);



    }













    //inner html <br>





  })();
}

function testmoney() {
  
}








function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
  
  var noteModal = true
     collectionCounter = localStorage.getItem('collectionNumber')
     //console.log(collectionCounter)
   collectionCounter++;
     //console.log(collectionCounter)
   
   var collectionTitles = []
    var collectionDetailLinks = []
   var collectionDocLinks = []
   var collectionNote = []
   
   //collectionTitles.push(saveCollectionTitle)
   //collectionDetailLinks.push(saveCollectionDetailLink)
   //collectionDocLinks.push(saveCollectionDocLink)
   
   
   
    
     
   
    
     
     var title = 'title'.concat(collectionCounter)
     var detailLink = 'detailLink'.concat(collectionCounter)
     var docLink = 'docLink'.concat(collectionCounter)
     var itemNote = 'itemNote'.concat(collectionCounter)
     var itemDate = 'itemDate'.concat(collectionCounter)
     var feedName = 'feedName'.concat(collectionCounter)
     var branchName = 'branchName'.concat(collectionCounter)
     var topicName = 'topicName'.concat(collectionCounter)
 
   
   
     localStorage.setItem(title,saveCollectionTitle)
     
     localStorage.setItem(detailLink,saveCollectionDetailLink)
    
     localStorage.setItem(docLink,saveCollectionDocLink)
     localStorage.setItem(itemNote,saveCollectionNote)
 
     localStorage.setItem(itemDate,saveCollectionDate)
     localStorage.setItem(feedName,saveCollectionFeedName)
     localStorage.setItem(branchName,saveCollectionBranchName)
     localStorage.setItem(topicName,saveCollectionTopicName)
   
     localStorage.setItem('collectionNumber', collectionCounter)
    
     function saveNote() {
   
       var searchItem = document.getElementById('noteModal').value
       
     
       var indexStore = localStorage.getItem('collectionNumber')
       var itemNote = 'itemNote'.concat(collectionCounter)
       
 
       
       localStorage.setItem(itemNote,searchItem)
 
     window.location.reload()
     
       //Override note value null -> "input"
     
     
       
     
     
     
     
     }
 
   //return collectionCounter;
 
 return(
  render(
     <>
     <AddNoteModal></AddNoteModal>
 
     
     
                             </>
   )
 )
   
     //[x] localStorage.getItem('collectionNumber')
     //[x] localStorage.getItem(saveCollectionNote)
 
   
  
     //<Button onClick={() => { noteModal = false; console.log(localStorage.getItem(saveCollectionNote)) }}>test</Button>
   
     //onClick={() => saveNote()}
   
   }



function createHouseCommitteeOnAgriculture() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=AG00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Housing and Urban Development", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseComitteeOnAgriculture'));





 














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnTransportationAndInfrastructure() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=PW00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Transportation and Infrastructure", "Legislative", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnTransportationAndInfrastructure'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




function createDepartmentOfTheInterior() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.doi.gov/feeds/list/22038/rss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of the Interior", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('theInteriorDepartment'));





   














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfAgriculture() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.usda.gov/rss/home.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Agriculture", "Executive", "Environment" )




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('agricultureDepartment'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createDepartmentOfHousingAndUrbanDevelopment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.hud.gov/sites/dfiles/Main/documents/hudrss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Housing and Urban Development", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('housingAndUrbanDevelopmentDepartment'));





    














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




function createEnvironmentalProtectionAgency() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]


      

      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], "EPA", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epa'));



















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createEnvironmentalProtectionAgencyMobile() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]


      

      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], "Environmental Protection Agency", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epaMobile'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createNationalScienceFoundation() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nsf.gov/rss/rss_www_discoveries.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "National Science Foundation Research News", "Executive", "Environment")



        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nsf'));





      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createNationWeatherServices(){

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.weather.gov/rss_page.php?site_name=nws');






    feed.items.forEach(item => {

      



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      console.log('nws link: ' + billLink)
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>

        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open('https://www.wpc.ncep.noaa.gov/discussions/hpcdiscussions.php?disc=pmdspd&version=0&fmt=reg', "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "National Weather Service Updates", "N/A", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nws'));





      













      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}





function createNationalAeronauticsAndSpaceAdministration() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nasa.gov/rss/dyn/breaking_news.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "NASA", "Executive", "Environment")


        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nasa'));





  














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createHouseCommitteeOnNaturalResources() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=II00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "NASA", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnNaturalResources'));



   
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}





const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);





export const Environment = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [noaaModalIsOpen, setNOAAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  const [
    number1ModalIsOpen, setNumber1ModalIsOpen,
    number2ModalIsOpen, setNumber2ModalIsOpen,
    number3ModalIsOpen, setNumber3ModalIsOpen,
    number4ModalIsOpen, setNumber4ModalIsOpen,
    number5ModalIsOpen, setNumber5ModalIsOpen,
    number6ModalIsOpen, setNumber6ModalIsOpen,
    number7ModalIsOpen, setNumber7ModalIsOpen,
    number8ModalIsOpen, setNumber8ModalIsOpen,
    number9ModalIsOpen, setNumber9ModalIsOpen,
    number10ModalIsOpen, setNumber10ModalIsOpen,
    number11ModalIsOpen, setNumber11ModalIsOpen,
    number12ModalIsOpen, setNumber12ModalIsOpen,
    number13ModalIsOpen, setNumber13ModalIsOpen,
    number14ModalIsOpen, setNumber14ModalIsOpen,
    number15ModalIsOpen, setNumber15ModalIsOpen,
    number16ModalIsOpen, setNumber16ModalIsOpen,
    number17ModalIsOpen, setNumber17ModalIsOpen,
    number18ModalIsOpen, setNumber18ModalIsOpen,
    number19ModalIsOpen, setNumber19ModalIsOpen,
    number20ModalIsOpen, setNumber20ModalIsOpen,
    number21ModalIsOpen, setNumber21ModalIsOpen,
    number22ModalIsOpen, setNumber22ModalIsOpen,
    number23ModalIsOpen, setNumber23ModalIsOpen,
    number24ModalIsOpen, setNumber24ModalIsOpen,
    number25ModalIsOpen, setNumber25ModalIsOpen,
  ] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  return(
    <>
      <BrowserView>
        <div id='homePage'>


        <div id='homebg'>
        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

        <DesktopNavbar/>          
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' ><Tree></Tree><b>Environment</b></h3> 
              
              </Row>
              </div> 
         
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfJusticeHeading' onClick={() =>
                            openModal('National Weather Service Update',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at' 
                            name='National Weather Service Update' branch='Executive' topic='Environment' buttonName='Doc' />)}><b>National Weather Service Update</b></h4>

                        <div id='nws'></div>
                    
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at', 'nws', 
                            'National Weather Service Update', 'N/A', 
                            'Environment', 'Document', GenerateFeed)}

                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfJusticeHeading' onClick={() => setNumber1ModalIsOpen(true)}><b>EPA</b></h4>

                        <div id='epa'></div>
    
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=epa&order_by=created_at', 'epa', 
                            'EPA', 'N/A', 
                            'Environment', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber1ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>EPA</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=epa&order_by=created_at' 
                            name='EPA' branch='N/A' topic='Environment' buttonName='Document' />
                          </Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfJusticeHeading'><b>National Science Foundation Research News</b></h4>

<div id='nsf'></div>

{CreateFeedItem('https://www.nsf.gov/rss/rss_www_discoveries.xml', 'nsf', 'National Science Foundation Research News', 'Executive', 'Environment', NationalScienceFoundation)}


<Modal id='departmentOfStateModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSFModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>National Science Foundation Research News</h3></Modal.Header>
  <NationalScienceFoundation />
</Modal>

                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfJusticeHeading'><b>NASA</b></h4>

                        <div id='nasa'></div>
                   
                        {CreateFeedItem('https://www.nasa.gov/rss/dyn/breaking_news.rss', 'nasa', 'NASA', 'Executive', 'Environment', NationalAeronauticsAndSpaceAdministration)}


                        <Modal id='whiteHouseDocsModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Aeronautics and Space Administration (NASA)</h3></Modal.Header>
                          <NationalAeronauticsAndSpaceAdministration />
                        </Modal>
                        {/*

                        <h4 id='departmentOfJusticeHeading'><b>NOAA Ocean Service News</b></h4>

                        <div id='noaaOSN'></div>
                        <div id='noaaOSN2'></div>
                        {createNationalOceanicAndAtmosphericAdministrationOSN()}
                        <Button size='lg' id='whDocsViewMoreButton' onClick={() => setNOAAModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

                        <Modal id='whiteHouseDocsModal' show={noaaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNOAAModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>NOAA Ocean Service News</h3></Modal.Header>
                          <createNationalOceanicAndAtmosphericAdministrationOSN />
                        </Modal>
                        */}



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>


          

           

        


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfJusticeHeading' onClick={() => window.open('/us/departmentofagriculture', "_self")}><b>Department of Agriculture</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                        <div id='agricultureDepartment'></div>
                   
                        {CreateFeedItem('https://www.usda.gov/rss/home.xml', 'agricultureDepartment', 'Department of Agriculture', 'Executive', 'Environment', DepartmentOfAgriculture)}

                        <Modal id='whiteHouseDocsModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
                          <DepartmentOfAgriculture />
                        </Modal>



                      </div>
                    </Col>


                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfJusticeHeading'><b>House Committee on Agriculture</b></h4>

                        <div id='houseComitteeOnAgriculture'></div>
                     
                        {CreateFeedItem('https://www.usda.gov/rss/home.xml', 'houseComitteeOnAgriculture', 'House Committee on Agriculture', 'Legislative', 'Environment', HouseComitteeOnAgriculture)}

                        <Modal id='fullBillModal' show={houseComitteeOnAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseComitteeOnAgricultureModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee On Agriculture</h3></Modal.Header>
                          <HouseComitteeOnAgriculture />
                        </Modal>
                      </div>
                    </Col>


                  </Row>
                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
   


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfJusticeHeading' onClick={() => window.open('/us/departmentofhousingandurbandevelopment', "_self")}><b>Department of Housing and Urban Development</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                        <div id='housingAndUrbanDevelopmentDepartment'></div>
                  
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at', 'housingAndUrbanDevelopmentDepartment', 
                            'Department of Housing and Urban Development', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}







                      </div>
                    </Col>

                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on Natural Resources</b></h4>
                        <div id='houseCommitteeOnNaturalResources'></div>
                    



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=II00', 'houseCommitteeOnNaturalResources', 'House Committee on Natural Resources', 'Executive', 'Environment', HouseCommitteeOnNaturalResources)}

                        <Modal id='fullBillModal' show={houseCommitteeOnNaturalResourcesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnNaturalResourcesModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Natural Resources</h3></Modal.Header>
                          <HouseCommitteeOnNaturalResources />
                        </Modal>
                      </div>
                    </Col>



                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='feedHeadingBillsEnrolled'><b>House Committee on Transportation and Infrastructure</b></h4>
                        <div id='houseCommitteeOnTransportationAndInfrastructure'></div>
                        



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=PW00', 'houseCommitteeOnTransportationAndInfrastructure', 'House Committee on Transportation and Infrastructure', 'Executive', 'Environment', HouseCommitteeOnTransportationAndInfrastructure)}

                        <Modal id='fullBillModal' show={houseCommitteeOnTransportationAndInfrastructureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Transportation and Infrastructure</h3></Modal.Header>
                          <HouseCommitteeOnTransportationAndInfrastructure />
                        </Modal>
                      </div>
                    </Col>

                     

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                    
                      </div>
                    </Col>

                    





                  </Row>
                </Container>
              </Container>
            </div>

         
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

           <EnvironmentMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default Environment;