import React, { useState } from 'react';
import { Button, Modal, Navbar } from 'react-bootstrap';
import { Files } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import CandidateJSONTable from './CandidateJSONTable';
import CandidateJSONTableMobile from './CandidateJSONTableMobile';
import DesktopNavbar from './DesktopNavbar';
import MobileOffCanvas from './MobileOffCanvas'; // Replace with your component if needed
import MobileTopNavbar from './MobileTopNavbar';

function formatDateString(dateString) {
  return dateString; // Placeholder for now
}

function CandidatePage(props) {
  const [showModal, setShowModal] = useState(false);

  const { name, office, state, party, district, fillingDate, id } = props.location.state;

  // Check if the id starts with the letter "P"
  const idStartsWithP = /^P/.test(id);

  const handleViewMoreClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <BrowserView>
        <div id='homebg'>
          <DesktopNavbar/>
          <br />
          <div id='feedDisplay' style={{ padding: '20px', borderRadius: '18px', marginTop: '0em' }}>
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
                  <span>{name}</span>
                </div>
                <div style={{ color: 'white', fontSize: '20px' }}>
                  <span>{office} (Candidate) {state}  {party}</span>
                  {office === 'House' && district && <><br /><span>{district}</span></>}
                  <br />
                  <span>{formatDateString(fillingDate)}</span>
                </div>
              </div>
            </div>

            <br /><br></br><br></br>
            <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}><Files size={20} /> Read More</Button>
            <br />
            <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>Coming Soon</div>
            <br></br>
            <br></br>

            <Modal id='fullBillModal' size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header id='billModalHeader' closeButton><h3>{name}'s Details</h3></Modal.Header>
              <div id='fullBillFeed'>
                <p>{/* Detailed information */}</p>
              </div>
            </Modal>

          <br></br>
              <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                <h4>2024 Election Campaign Finances</h4>
                <CandidateJSONTable id={id} />
              </div>
     <br></br>
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <div id='homebg'>
        <MobileTopNavbar/>
          <br />
          <div id='feedDisplay' style={{ padding: '20px', borderRadius: '18px', marginTop: '10px', paddingTop: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                  <span>{name}</span>
                </div>
                <div style={{ color: 'white', fontSize: '16px' }}>
                  <span>{office}</span>
                  <br />
                  <span>{state}</span>
                  <br />
                  <span>{party}</span>
                  {office === 'House' && district && <><br /><span>{district}</span></>}
                </div>
              </div>
            </div>

            <br />
            <Button id='presidentViewMoreButton' onClick={handleViewMoreClick}><Files size={20} /> Read More</Button>
            <br />

          
              <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
              <h4>2024 Election Campaign Finances</h4>
              <br></br>
                <CandidateJSONTableMobile id={id} />
              </div>
      

            <Modal id='fullBillModal' size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header id='billModalHeader' closeButton><h3>{name}'s Details</h3></Modal.Header>
              <div id='fullBillFeed'>
                <p>{/* Detailed information */}</p>
              </div>
            </Modal>

            <div id='mobileNavbarSpacer'></div>
            <Navbar id='navbarMobileBottom' fixed="bottom">
              <div id='navbarSpacer'></div><div id='navbarSpacer'></div>
              <div id='changeBranchDropdownMobile'>
                <MobileOffCanvas />
              </div>
            </Navbar>
          </div>
        </div>
      </MobileView>
    </>
  );
}

export default CandidatePage;
