
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPI, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import ArmyDocuments from './ArmyDocuments';
import MarinesPressReleases from './MarinesPressReleases';
import NavyDocuments from './NavyDocuments';
import SpaceForceNews from './SpaceForceNews';
import NationalGuardNews from './NationalGuardNews';
import NSADocuments from './NSADocuments';
import ArmyCorpsEngineers from './ArmyCorpsEngineers';
import DefenseMobile from './DefenseMoible';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import CoastGuardDocuments from './CoastGuardDocuments';
import FEMADocuments from './FEMADocuments';
import SecretService from './SecretService';
import TSADocuments from './TSADocuments';
import CitizenshipAndImmigrationDocuments from './CitizenshipAndImmigrationDocuments';
import CustomsAndBorderDocs from './CustomsAndBorderDocs';
import CustomsEnforcementDocs from './CustomsEnforcementDocs';
import HomelandSecurityMobile from './HomelandSecurityMobile';
import DepartmentOfStateMobile from './DepartmentOfStateMobile';
import DoSPress from './DoSPress';
import ArmsControl from './ArmsControl';
import CounterTerrorism from './CounterTerrorism';
import DemocracyHumanRightsLabor from './DemocracyHumanRightsLabor';
import DiplomaticSecurity from './DiplomaticSecurity';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import AlcoholTobaccoFireArms from './AlcoholTobaccoFireArms';
import AntitrustDivision from './AntitrustDivision';
import DrugEnforcementAdministration from './DrugEnforcementAdministration';
import ExecutiveOfficeForImmigrationReview from './ExecutiveOfficeForImmigrationReview';
import FederalBureauOfInvestigation from './FederalBureauOfInvestigation';
import UnitedStatesMarshalsService from './UnitedStatesMarshalsService';
import ParoleCommission from './ParoleCommission';
import PrisonBureau from './PrisonBureau';
import NationalInstituteOfJustice from './NationalInstituteOfJustice';
import NationalInstituteOfCorrections from './NationalInstituteOfCorrections';
import JuvenileJusticeAndDellinquencyPreventionOfficee from './JuvenileJusticeAndDellinquencyPreventionOffice';
import JusticeProgramsOffice from './JusticeProgramsOffice';
import ForeignClaimsSettlement from './ForeignClaimsSettlement';
import JusticeMobile from './JusticeMobile';
import GenerateFeed from './GenerateFeed';
import GenerateFeedDepartment from './GenerateFeedDepartment';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfJusticeDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [justiceModalIsOpen, setJusticeModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
const [showMissionModal, setMissionModal] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfJustice.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Justice </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The mission of the Department of Justice (DOJ) is to enforce the law and defend the interests of the United States according to the law; to ensure public safety against threats foreign and domestic; to provide federal leadership in preventing and controlling crime; to seek just punishment for those guilty of unlawful behavior; and to ensure fair and impartial administration of justice for all Americans.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.justice.gov/", "_blank");
        }}>
          https://www.justice.gov/"
          </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Justice</h3></Modal.Header>
       
         <div id='fullBillFeed' >
         <p>The mission of the Department of Justice (DOJ) is to enforce the law and defend the interests of the United States according to the law; to ensure public safety against threats foreign and domestic; to provide federal leadership in preventing and controlling crime; to seek just punishment for those guilty of unlawful behavior; and to ensure fair and impartial administration of justice for all Americans.</p>

<p>The DOJ is comprised of 40 component organizations, including the Drug Enforcement Administration, the Federal Bureau of Investigation, the U.S. Marshals Service, and the Federal Bureau of Prisons. The Attorney General is the head of the DOJ and chief law enforcement officer of the federal government. The Attorney General represents the United States in legal matters, advises the President and the heads of the executive departments of the government, and occasionally appears in person before the Supreme Court.</p>

<p>With a budget of approximately $25 billion, the DOJ is the world’s largest law office and the central agency for the enforcement of federal laws.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.justice.gov/", "_blank");
   }}>
     https://www.justice.gov/
     </Link>
 </div>

       </Modal>
           <br></br> <br></br> <br></br>
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 onClick={() => setDepartmentOfJusticeModalIsOpen(true)} id='whBreifingFeedHeading'><b>News</b></h4>
                          <div id='justiceNews'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=justice&order_by=created_at', 'justiceNews', 
                      'Department of Justice News', 'Executive', 
                      'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfJusticeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfJusticeModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice News</h3></Modal.Header>
                <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=justice&order_by=created_at'
                                name='Department of Justice News' branch='Executive' topic='N/A' buttonName='Document' />
              </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>
                          <div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=justice-department', 'frDefense', 'Department of Justice Documents', 'Executive', 'N/A', DepartmentOfJusticeDocs)}

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice Documents</h3></Modal.Header>
                  <DepartmentOfJusticeDocs/>
                </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

            
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/antitrust-division', "_self")}}><b>Antitrust Division</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defAd'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Antitrust%20Division', 'defAd',
              'Antitrust Division Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Antitrust Division</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Antitrust%20Division'
                name='Antitrust Division' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/dea', "_self")}}><b>Drug Enforcement Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDea'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Drug%20Enforcement%20Administration', 'defDea',
              'Drug Enforcement Administration Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Drug Enforcement Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Drug%20Enforcement%20Administration'
                name='Drug Enforcement Administration' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
        <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/justice-programs', "_self")}}><b>Justice Programs Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defJpo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Justice%20Programs%20Office', 'defJpo',
              'Justice Programs Office Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Justice Programs Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Justice%20Programs%20Office'
                name='Justice Programs Office' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fbi', "_self")}}><b>Federal Bureau of Investigation</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFbi'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Bureau%20of%20Investigation', 'defFbi',
              'Federal Bureau of Investigation Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Bureau of Investigation</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Bureau%20of%20Investigation'
                name='Federal Bureau of Investigation' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/federal-prison-industries', "_self")}}><b>Federal Prison Industries</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFpi'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Prison%20Industries', 'defFpi',
              'Federal Prison Industries Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Prison Industries</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Prison%20Industries'
                name='Federal Prison Industries' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/foreign-claims', "_self")}}><b>Foreign Claims Settlement Commission</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFcsc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission', 'defFcsc',
              'Foreign Claims Settlement Commission Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Foreign Claims Settlement Commission</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission'
                name='Foreign Claims Settlement Commission' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>


<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/juvenile-justice', "_self")}}><b>Juvenile Justice and Delinquency Prevention Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defJjdp'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office', 'defJjdp',
              'Juvenile Justice and Delinquency Prevention Office Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Juvenile Justice and Delinquency Prevention Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office'
                name='Juvenile Justice and Delinquency Prevention Office' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-institute-corrections', "_self")}}><b>National Institute of Corrections</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNic'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Corrections', 'defNic',
              'National Institute of Corrections Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Institute of Corrections</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Corrections'
                name='National Institute of Corrections' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-institute-justice', "_self")}}><b>National Institute of Justice</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNij'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Justice', 'defNij',
              'National Institute of Justice Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Institute of Justice</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Justice'
                name='National Institute of Justice' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/parole-commission', "_self")}}><b>Parole Commission</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Parole%20Commission', 'defPc',
              'Parole Commission Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Parole Commission</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Parole%20Commission'
                name='Parole Commission' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
        <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/marshals-service', "_self")}}><b>United States Marshals Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defUsms'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=United%20States%20Marshals%20Service', 'defUsms',
              'United States Marshals Service Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United States Marshals Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=United%20States%20Marshals%20Service'
                name='United States Marshals Service' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
          
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>


           

      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <JusticeMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfJusticeDesk;