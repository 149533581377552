import React, { useState } from 'react';
import { CreateFeedAPI } from './Functions';
import { Container, Row, Col } from 'react-bootstrap';
import GenerateFeed from './GenerateFeed';
import { MobileView } from 'react-device-detect';
import TemplatePageSpecificMobile from './TemplatePageSpecificMobile';

export const TemplatePageHome = ({ feeds }) => {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  return (
    <div id='homebg'>
    <div id='branchesTab'>
      <Row id='branchesRow'>
        
        {feeds.slice(0, 3).map((feed, index) => (
          <Col key={index} id='homeColumn'>
            <h3 id='branchHeading' onClick={() => openModal(feed.feedName, <feed.FeedFunction url={feed.apiURL} 
                            name={feed.feedName} branch={feed.feedBranch} topic={feed.feedTopic} buttonName={feed.feedButtonName} />)}>
              {feed.feedName}
            </h3>
            <div id={feed.divID}></div>
            {CreateFeedAPI(feed.apiURL,
                           feed.divID,
                           feed.feedName,
                           feed.feedBranch,
                           feed.feedTopic,
                           feed.feedButtonName,
                           GenerateFeed
            )}


          </Col>
        ))}
      </Row>
    </div>
    <MobileView>
      <TemplatePageSpecificMobile/>
    </MobileView>
    </div>
  );
};

export default TemplatePageHome;
