
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS, CreateFeedAPI } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import ArmyDocuments from './ArmyDocuments';
import MarinesPressReleases from './MarinesPressReleases';
import NavyDocuments from './NavyDocuments';
import SpaceForceNews from './SpaceForceNews';
import NationalGuardNews from './NationalGuardNews';
import NSADocuments from './NSADocuments';
import ArmyCorpsEngineers from './ArmyCorpsEngineers';
import DefenseMobile from './DefenseMoible';
import AirForceDocuments from './AirForceDocuments';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import GenerateFeed from './GenerateFeed';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const Defense = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)

  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
  const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
  const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
  const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
  const [federalRegisterVeteransAffairsDepartmentModalIsOpen, setFederalRegisterVeteransAffairsDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHomelandSecurityDepartmentModalIsOpen, setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
  const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
  const [federalRegisterLaborDepartmentModalIsOpen, setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
  const [federalRegisterEducationDepartmentModalIsOpen, setFederalRegisterEducationDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen, setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen, setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [defenseModalIsOpen, setDefenseModalIsOpen] = useState(false)

  const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  const [showMissionModal, setMissionModal] = useState(false);

  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfDefense.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Defense </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The mission of the Department of Defense (DOD) is to provide the military forces needed to deter war and to protect the security of our country. The department’s headquarters is at the Pentagon.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.defense.gov/", "_blank");
        }}>
          https://www.defense.gov/
          </Link></span> 
          <br></br>
          <Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>

</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Defense</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The mission of the Department of Defense (DOD) is to provide the military forces needed to deter war and to protect the security of our country. The department’s headquarters is at the Pentagon.</p>

<p>The DOD consists of the Departments of the Army, Navy, and Air Force, as well as many agencies, offices, and commands, including the Joint Chiefs of Staff, the Pentagon Force Protection Agency, the National Security Agency, and the Defense Intelligence Agency. The DOD occupies the vast majority of the Pentagon building in Arlington, Virginia.</p>

<p>The DOD is the largest government agency, with more than 1.4 million men and women on active duty, more than 700,000 civilian personnel, and 1.1 million citizens who serve in the National Guard and Reserve forces. Together, the military and civilian arms of DOD protect national interests through war-fighting, providing humanitarian aid, and performing peacekeeping and disaster relief services.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=than%2041%2C000%C2%A0%20employees.-,DEPARTMENT%20OF%20DEFENSE,-The%20mission%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=than%2041%2C000%C2%A0%20employees.-,DEPARTMENT%20OF%20DEFENSE,-The%20mission%20of
     </Link>
 </div>

       </Modal>

            <br></br><br></br><br></br>
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'  onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>
                          <div id='defenseDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment', 'Department of Defense', 'Executive', 'Foreign Affairs', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=defense&order_by=created_at' 
                            name='Department of Defense News' branch='Executive' topic='N/A' buttonName='' />
                </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>
                          <div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}
<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>
     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/army', "_self")
                  }}>
                    <b>Army</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defArmy'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=defense&agency=army%20department', 'defArmy',
                    'Army Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Army</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=defense&agency=army%20department'
                      name='Army' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/navy', "_self")
                  }}>
                    <b>Navy</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defNavy'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=defense&agency=navy%20department', 'defNavy',
                    'Navy Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Navy</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=defense&agency='
                      name='Navy' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/airforce', "_self")
                  }}>
                    <b>Air Force</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defAirForce'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Air%20Force%20Department', 'defAirForce',
                    'Air Force Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Air Force</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=defense&agency=Air%20Force%20Department'
                      name='Air Force' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
              <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>Marines Press Releases</b></h4>
                          <div id='marinesPress'></div>

{CreateFeedItemBillWithDescription('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/marine.xml', 'marinesPress', 'Marines Press Release', 'Executive', 'N/A', MarinesPressReleases)}

     
                        </div>
                      </Col>
            </Row>
          </Container>
        </Container>
      </div>

                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-acquisition-regulations-system', "_self")}}><b>Defense Acquisition Regulations System</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDars'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Acquisition%20Regulations%20System', 'defDars',
              'Defense Acquisition Regulations System Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Acquisition Regulations System</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Acquisition%20Regulations%20System'
                name='Defense Acquisition Regulations System' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-contract-audit-agency', "_self")}}><b>Defense Contract Audit Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDcaa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Contract%20Audit%20Agency', 'defDcaa',
              'Defense Contract Audit Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Contract Audit Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Contract%20Audit%20Agency'
                name='Defense Contract Audit Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-contract-management-agency', "_self")}}><b>Defense Contract Management Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDcm'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Contract%20Management%20Agency', 'defDcm',
              'Defense Contract Management Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Contract Management Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Contract%20Management%20Agency'
                name='Defense Contract Management Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-finance-accounting-service', "_self")}}><b>Defense Finance and Accounting Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDfas'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Finance%20and%20Accounting%20Service', 'defDfas',
              'Defense Finance and Accounting Service Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Finance and Accounting Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Finance%20and%20Accounting%20Service'
                name='Defense Finance and Accounting Service' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-information-systems-agency', "_self")}}><b>Defense Information Systems Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDisa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Information%20Systems%20Agency', 'defDisa',
              'Defense Information Systems Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Information Systems Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Information%20Systems%20Agency'
                name='Defense Information Systems Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-intelligence-agency', "_self")}}><b>Defense Intelligence Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDia'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Intelligence%20Agency', 'defDia',
              'Defense Intelligence Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Intelligence Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Intelligence%20Agency'
                name='Defense Intelligence Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-legal-services-agency', "_self")}}><b>Defense Legal Services Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDlsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Legal%20Services%20Agency', 'defDlsa',
              'Defense Legal Services Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Legal Services Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Legal%20Services%20Agency'
                name='Defense Legal Services Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-logistics-agency', "_self")}}><b>Defense Logistics Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDla'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Logistics%20Agency', 'defDla',
              'Defense Logistics Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Logistics Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Logistics%20Agency'
                name='Defense Logistics Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-security-cooperation-agency', "_self")}}><b>Defense Security Cooperation Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDsca'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Security%20Cooperation%20Agency', 'defDsca',
              'Defense Security Cooperation Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Security Cooperation Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Security%20Cooperation%20Agency'
                name='Defense Security Cooperation Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-technical-information-center', "_self")}}><b>Defense Technical Information Center</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDtic'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Technical%20Information%20Center', 'defDtic',
              'Defense Technical Information Center Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Technical Information Center</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Technical%20Information%20Center'
                name='Defense Technical Information Center' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-threat-reduction-agency', "_self")}}><b>Defense Threat Reduction Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDtra'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Threat%20Reduction%20Agency', 'defDtra',
              'Defense Threat Reduction Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Threat Reduction Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Threat%20Reduction%20Agency'
                name='Defense Threat Reduction Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-guard-bureau', "_self")}}><b>National Guard Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNgb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=National%20Guard%20Bureau', 'defNgb',
              'National Guard Bureau Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Guard Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=National%20Guard%20Bureau'
                name='National Guard Bureau' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

             

           

      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link style={{color: '#21cff5'}} onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <DefenseMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default Defense;