import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Image, Navbar, Button, Carousel, Spinner, Nav, ModalBody } from 'react-bootstrap';
import { Files, HeartFill } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import DesktopNavbar from './DesktopNavbar';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import CreateFeedItemStateRepsForPage from './CreateFeedItemStateRepsForPage';
import StatePickerElection from './StatePickerElection';
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
require('dotenv').config();

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

// Helper function to capitalize the first letter of each word
const capitalizeStateName = (state) => {
  return state
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const StateElectionPage = () => {
  const { stateName } = useParams();
  const [stateData, setStateData] = useState(null);
  const [showBioModal, setBioModal] = useState(false);
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  const stateAbbreviations = {
    alabama: 'AL',
    alaska: 'AK',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    florida: 'FL',
    georgia: 'GA',
    hawaii: 'HI',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    new_hampshire: 'NH',
    new_jersey: 'NJ',
    new_mexico: 'NM',
    new_york: 'NY',
    north_carolina: 'NC',
    north_dakota: 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    rhode_island: 'RI',
    south_carolina: 'SC',
    south_dakota: 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    west_virginia: 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
  };

  useEffect(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
    };
    
    const formattedStateName = stateName.replace(/_/g, ' ');

    fetch('https://api.govglance.org/posts/recent?limit=1000&skip=0&schema=united_states_of_america&table=state_election_info&order_by=updated_at', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const state = data.find((item) => item.state.toLowerCase() === formattedStateName.toLowerCase());

        // If no state data is found, we still allow candidate data to load
        if (state) {
          setStateData(state);
        } else {
          setStateData({
            state: formattedStateName, 
            election_info: {},  // No election info, but allow candidate data to load
            polling_info_urls: [],
            phone_numbers: [],
            eac_url: ''
          });
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [stateName]);

  if (!stateData) return <div id='homebg'><Spinner animation="grow" variant="light" /></div>;

  const {
    state,
    eac_url,
    phone_numbers,
    polling_info_urls,
    election_info,
  } = stateData;

  const formattedStateKey = state.toLowerCase().replace(/ /g, '_');
  const stateAbbreviation = stateAbbreviations[formattedStateKey];

  const capitalizedStateName = capitalizeStateName(state);
  const camelCaseStateName = state.split(' ').map((word, index) => {
    if (index === 0) return word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join('');
  const flagImageSrc = `../../${camelCaseStateName}Flag.png`;

  return (
    <>
<BrowserView>
  <div id='homebg'>
    <DesktopNavbar />
    <div id='feedDisplay' style={{ padding: '20px', borderRadius: '18px', marginTop: '0em' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id='profileImageState' >
  <Image src={flagImageSrc} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
</div>
        <div style={{ marginLeft: '20px', color: 'white', fontSize: '20px' }}>
          <h3>{capitalizedStateName} Elections</h3>
        </div>
      </div>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '30px', color: 'white', fontSize: '20px', marginTop: '20px' }}>
          {/* Election Info */}
          <div style={{ flex: '1' }}>
            <ul>
              {election_info && election_info["General Election: November 5, 2024"] && (
                <li style={{ fontSize: '16px' }}>
                  <strong>General Election: November 5, 2024:</strong>
                  <ul style={{ fontSize: '16px' }}>
                    {election_info["General Election: November 5, 2024"].map((date, index) => (
                      <li style={{ fontSize: '14px' }} key={index}>{date}</li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>
          </div>

          {/* Buttons Section */}
          <div style={{ flex: '2', display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
            {polling_info_urls && Object.entries(polling_info_urls).map(([key, url]) => (
              <Button
                id='navbarAboutButtonDesktop'
                key={key}
                style={{ marginBottom: '10px' }}
                onClick={() => window.open(url, '_blank')}
              >
                {key}
              </Button>
            ))}
            {!polling_info_urls["Register to Vote"] && eac_url && (
              <Button
                id='navbarAboutButtonDesktop'
                style={{ marginBottom: '10px' }}
                onClick={() => window.open(eac_url, '_blank')}
              >
                Register to Vote 
              </Button>
            )}
          </div>

          {/* Phone Numbers */}
          {/* <div style={{ flex: '1', textAlign: 'right' }}>
            <ul>
              {phone_numbers.map((phone, index) => (
                <li key={index}>{phone}</li>
              ))}
            </ul>
          </div> */}
        </div>
      </Container>
    </div>
    {/* Other content remains unchanged */}
    <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        {/* House Candidates Carousel */}
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4 id='billFeedHeadingElection' onClick={() =>
              openModal(`2024 House Candidates`,
                <GenerateFeedStateRepsAll
                  stateAbbreviation={stateAbbreviation}
                  candidateType='House' />
            )}>
              <b>2024 House Candidates</b>
            </h4>
            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='house'></div>
                    <CreateFeedItemStateRepsForPage divID="house" candidateType="House" stateAbbreviation={stateAbbreviation} skip={0} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='house2'></div>
                    <CreateFeedItemStateRepsForPage divID="house2" candidateType="House" stateAbbreviation={stateAbbreviation} skip={1} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='house3'></div>
                    <CreateFeedItemStateRepsForPage divID="house3" candidateType="House" stateAbbreviation={stateAbbreviation} skip={2} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                        openModal(`2024 House Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='House' />
                      )}>View all 2024 House Candidates</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        {/* Senate Candidates Carousel */}
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4 id='billFeedHeading' onClick={() =>
              openModal(`2024 Senate Candidates`,
                <GenerateFeedStateRepsAll
                  stateAbbreviation={stateAbbreviation}
                  candidateType='Senate' />
            )}>
              <b>2024 Senate Candidates</b>
            </h4>
            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='senate'></div>
                    <CreateFeedItemStateRepsForPage divID="senate" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={0} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='senate2'></div>
                    <CreateFeedItemStateRepsForPage divID="senate2" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={1} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='senate3'></div>
                    <CreateFeedItemStateRepsForPage divID="senate3" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={2} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                        openModal(`2024 Senate Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='Senate' />
                      )}>View all 2024 Senate Candidates</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* <h3 id='branchHeading'> <b id='branchHeadingBold'>State</b></h3> */}

<br></br>


<StatePickerElection/>







         
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
  </div>
</BrowserView>





<MobileView>
  <div id='homebg'>
    <MobileTopNavbar />
    <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <div id="profileImageOnPageDepartmentState" >
  <Image id="profileImage-imageOnPageDepartment" src={flagImageSrc} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
</div>
          <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <h5 id="branchHeadingMobile">{capitalizedStateName} Elections</h5>
          </div>
        </div>
      </div>

      {/* General Election Info Display */}
      <div id="generalElectionInfo" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
        {election_info && election_info["General Election: November 5, 2024"] && (
          <ul>
            <li style={{ fontSize: '16px' }}>
              <strong>General Election: November 5, 2024:</strong>
              <ul style={{ fontSize: '16px' }}>
                {election_info["General Election: November 5, 2024"].map((date, index) => (
                  <li style={{ fontSize: '14px' }} key={index}>{date}</li>
                ))}
              </ul>
            </li>
          </ul>
        )}
      </div>

      {/* "More Info" Button */}
      <Button id='presidentViewMoreButton' onClick={() => setBioModal(true)}> 
        <Files size={20}></Files> More Info
      </Button>

      {/* Carousels for House and Senate Candidates */}
      <h5 onClick={() =>
        openModal(`2024 House Candidates`,
          <GenerateFeedStateRepsAll
            stateAbbreviation={stateAbbreviation}
            candidateType='House' />
      )} id='presidentialFeedHeadingMobile'>2024 House Candidates</h5>
      <Col>
        <Carousel controls={false}>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div className='courtNewsMobile' id='house'></div>
                <CreateFeedItemStateRepsForPage divID="house" candidateType="House" stateAbbreviation={stateAbbreviation} skip={0} />
              </Col>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div className='courtNewsMobile' id='house2'></div>
                <CreateFeedItemStateRepsForPage divID="house2" candidateType="House" stateAbbreviation={stateAbbreviation} skip={1} />
              </Col>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div className='courtNewsMobile' id='house3'></div>
                <CreateFeedItemStateRepsForPage divID="house3" candidateType="House" stateAbbreviation={stateAbbreviation} skip={2} />
              </Col>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div id='viewFullPageButton'>
                  <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(`2024 House Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation={stateAbbreviation}
                        candidateType='House' />
                  )}>View all 2024 House Candidates</Button>
                </div>
              </Col>
            </div>
          </Carousel.Item>
        </Carousel>
      </Col>

      <h5 onClick={() =>
        openModal(`2024 Senate Candidates`,
          <GenerateFeedStateRepsAll
            stateAbbreviation={stateAbbreviation}
            candidateType='Senate' />
      )} id='presidentialFeedHeadingMobile'>2024 Senate Candidates</h5>
      <Col>
        <Carousel controls={false}>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div className='courtNewsMobile' id='senate'></div>
                <CreateFeedItemStateRepsForPage divID="senate" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={0} />
              </Col>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div className='courtNewsMobile' id='senate2'></div>
                <CreateFeedItemStateRepsForPage divID="senate2" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={1} />
              </Col>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div className='courtNewsMobile' id='senate3'></div>
                <CreateFeedItemStateRepsForPage divID="senate3" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={2} />
              </Col>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div id='viewFullPageButton'>
                  <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(`2024 Senate Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation={stateAbbreviation}
                        candidateType='Senate' />
                  )}>View all 2024 Senate Candidates</Button>
                </div>
              </Col>
            </div>
          </Carousel.Item>
        </Carousel>
      </Col>

      {/* Modal Content */}
      <Modal id='fullBillModal' size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
  <Modal.Header id='billModalHeader' closeButton>
    <h3>{capitalizedStateName} Voting Information</h3>
  </Modal.Header>
  <div id='fullBillFeed'>
    {/* General Election Info */}
    <ul>
      {election_info && election_info["General Election: November 5, 2024"] && (
        <li style={{ fontSize: '16px' }}>
          <strong>General Election: November 5, 2024:</strong>
          <ul style={{ fontSize: '16px' }}>
            {election_info["General Election: November 5, 2024"].map((date, index) => (
              <li style={{ fontSize: '14px' }} key={index}>{date}</li>
            ))}
          </ul>
        </li>
      )}
    </ul>

    {/* Polling Info URLs as Buttons in a Column */}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
      {polling_info_urls && Object.entries(polling_info_urls).map(([key, url]) => (
        <Button
          id='navbarAboutButtonDesktop'
          key={key}
          onClick={() => window.open(url, '_blank')}
        >
          {key}
        </Button>
      ))}

      {/* Display eac_url as a Button if "Register to Vote" is not in polling_info_urls */}
      {!polling_info_urls["Register to Vote"] && eac_url && (
        <Button
          id='navbarAboutButtonDesktop'
          onClick={() => window.open(eac_url, '_blank')}
        >
          Register to Vote
        </Button>
      )}
    </div>
  </div>
</Modal>

      {/* Custom Modal for Other Info */}
      <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

      <Navbar id='navbarMobileBottom' fixed="bottom">
        <div id='changeBranchDropdownMobile'>
          <MobileOffCanvas />
        </div>
      </Navbar>
    </div>
  </div>
</MobileView>




    </>
  );
};

export default StateElectionPage;
