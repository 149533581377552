import React, { useState,useEffect, memo } from 'react';
import { Button, Card, Form, Modal, Spinner } from 'react-bootstrap';
import { Files, FileText, FolderPlus, InfoCircle } from 'react-bootstrap-icons';
import ItemDetailPage from './ItemDetailPage';

var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}

const APICongressionalBillPull =  React.memo(({ congressionalBillId }) => {
  //console.log(congressionalBillId)
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [billInfo, setBillInfo] = useState(null);
  require('dotenv').config();
  const apiKey = process.env.REACT_APP_API_KEY;
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }


  useEffect(() => {
    let isMounted = true;

    const fetchBillInfo = async () => {
      try {
        const response = await fetch(`https://api.govglance.org/members/congresssional_bill/${congressionalBillId}`, {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + apiKey,
          }
        });
        const data = await response.json();

        if (isMounted) {
          setBillInfo(data);
         console.log(billInfo)
        }
      } catch (error) {
        console.error('Error fetching bill info:', error);
      }
    };

    fetchBillInfo();

    return () => {
      isMounted = false;
    };
  }, [congressionalBillId, apiKey]);

  return (
    <>
      {billInfo ? (
        <>
         <span>{billInfo.title.length > 180 ? `${billInfo.title.slice(0, 177)}...` : billInfo.title}</span>
          
          <Card.Body id='billBodyText'onClick={(e) => {
                    e.preventDefault();
                   
                    window.open(billInfo.download.pdfLink, "_blank");
                  }} ></Card.Body>
          <p>
          <Button id='billLink' onClick={(e) => {
                  e.preventDefault();
                  window.open(billInfo.url, "_blank");
                }}><InfoCircle></InfoCircle> Details</Button>
                
                  <Button id='viewBillButton' onClick={(e) => {
                    e.preventDefault();
                   
                    window.open(billInfo.download.pdfLink, "_blank");
                  }}>
                    <FileText></FileText> Bill
                  </Button>

                  <Button id='addToCollectionButton' onClick={() => {
              setCollectionModalIsOpen(true);
              SaveFeedItem(billInfo.title, billInfo.url, billInfo.download.pdfLink, formatDate(billInfo.date_issued), '', "Bill Updates", "Legislation", "N/A", "");
            }}><FolderPlus></FolderPlus></Button>
                  </p>
                  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
    centered onHide={setCollectionModalIsOpen}>
                                 
                                  <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>
    
                                  <Modal.Body id='descriptionModalBody'>
                                              {storedDescription}
                                              {storedDate}
                                  <div id='fullBill'>
                             <Form id='noteModalForm'>
             <Form.Group id='noteModalForm'  className="mb-3" >
               <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
                 type="text"
                 as={"textarea"}
                 rows={2}
                 />
                 <br></br>
    
             </Form.Group>
             </Form>
    
             <Modal.Footer id='addNoteModalFooter'> 
    
    <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
      storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
        Save To Collection
      </Button>
      </Modal.Footer> 
    
               </div>
               </Modal.Body>
                                  </Modal>
                 
        </>
      ) : (
        <span><Spinner animation="grow" variant="light" /></span>
      )}
    </>
  );
});

export default memo(APICongressionalBillPull);