import React, { useState } from 'react';
import { CreateFeedAPI } from './Functions';
import { Container, Row, Col, Modal, Button, Image } from 'react-bootstrap';
import GenerateFeed from './GenerateFeed';
import { Link } from 'react-router-dom';
import { Files } from 'react-bootstrap-icons';

export const TemplatePageSpecificMobile = ({ feeds }) => {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });
  const [lawsModalIsOpen, setLawsModalIsOpen] = useState(false)
  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
    
  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  return (
    <div id='homebg'>
 
 <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/legislativeBranch.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
      {/* <Pen></Pen> */}
        <h5 id='branchHeadingMobile'>Legislative Branch</h5>
      </div>
    </div>
    <div style={{ marginLeft: 'auto' }}>
     
    </div>
  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  Established by Article I of the Constitution, the Legislative Branch consists of the House of Representatives and the Senate, which together form the United States Congress.
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  <br></br>
  <br></br>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <Link style={{color: '#21cff5'}} onClick={(e) => { e.preventDefault(); window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/", "_blank") }} id='pageLink'><h5 id='presidentialFeedHeadingMobile'>Official Site</h5></Link>
  </div>
  </div>
</div>
 <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setFullBillModalIsOpen(true)}>Bill Updates</h5>

 <div class='courtNewsMobile' id='bills'></div>
                    {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at', 'bills', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
                    <Modal id='fullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
                      <Modal.Header id='billModalHeader' closeButton><h3>Bill Updates</h3></Modal.Header>
                      <GenerateFeed url='&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at' 
                            name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />
                    </Modal>
</Col>



<Col>
    <h5 id='presidentialFeedHeadingMobile'onClick={() => setBillsEnrolledModalIsOpen(true)} >Bills Enrolled (Sent to President)</h5>
   
   <div class='courtNewsMobile' id='billsEnrolled'></div>
                   {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=created_at', 
                   'billsEnrolled', 
                   'Bills Enrolled', 'Legislative', 
                   'N/A', 'Bill', GenerateFeed)}
             <Modal id='fullBillModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>Bills Enrolled (Sent to President)</h3></Modal.Header>
                     <GenerateFeed
 url='&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=created_at'
 name='Bills Enrolled'
 branch='Legislative'
 topic='N/A'
 buttonName='Bill'
/>
                   </Modal>



    </Col>



    <Col>
    <h5 id='presidentialFeedHeadingMobile' onClick={() => setLawsModalIsOpen(true)}>Laws</h5>

<div class='courtNewsMobile' id='newLaws'></div>
{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=created_at', 'newLaws', 
                            'Laws', 'Legislative', 
                            'N/A', 'Law', GenerateFeed)}
<Modal id='fullBillModal' show={lawsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLawsModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Laws</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=created_at' 
                            name='Laws' branch='Legislative' topic='N/A' buttonName='Law' />
                        </Modal>


 
    </Col>
    </div>
  );
};

export default TemplatePageSpecificMobile;
