import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, Files, Folder, Pen, ChatLeftQuote } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';
import CollectionPage from "./CollectionPage";
import{ ListItem, createFullBillFeed, saveToCollection, createHouseCommitteeOversightAndReform, createHouseCommitteeOnRules,
    createHouseCommitteeOnSmallBusiness, createHouseCommitteeOnScienceSpaceAndTechnology,
    createHouseCommitteeOnTransportationAndInfrastructure,
    createHouseCommitteeOnVeteransAffairs,
    createCongressionalBudgetOffice, createBillFeedTwo,
    createBillFeedTwoMobile, createReports, createHouseCommitteeOnAgriculture,
     createHouseCommitteeOnAppropriations, createHouseCommitteeOnArmedServices,
     createHouseCommitteeOnTheBudget, createHouseCommitteeOnEducationAndLabor,
    createHouseCommitteeOnEnergyAndCommerce, createHouseCommitteeOnEthics,
     createHouseCommitteeOnFinancialServices, createHouseCommitteeOnForeignAffairs, createHouseCommitteeOnHomelandSecurity,
    createHouseCommitteeOnHouseAdministration, 
    createHouseCommitteeOnTheJudiciary, createHouseCommitteeOnNaturalResources, 
     createHouseCommitteeOnNaturalResourcesMobile, createGAOReports,
     createNewLaws, createBillsEnrolled
    , createBillsEnrolledMobile, createTrendingBills
    , createHouseFloorToday, congressSearch, Speak, createGAOReportsMobile, createCongressionalHearings, 
    createBillStatus, CreateFeedItem, CreateFeedAPICommittee } from './Functions'
import BillStatus from "./BillStatus";
import BillUpdates from "./BillUpdates";
import NationalScienceFoundation from "./NationalScienceFoundation";
import NationalAeronauticsAndSpaceAdministration from "./NationalAeronauticsAndSpaceAdministration";
import NASAJPLNews from "./NASAJPLNews";
import NISTNews from "./NISTNews";
import GAOScienceAndTech from "./GAOScienceAndTech";
import HouseCommitteeOnScienceSpaceAndTechnology from "./HouseCommitteeOnScienceSpaceAndTechnology";
import MobileOffCanvas from "./MobileOffCanvas";
import MobileTopNavbar from "./MobileTopNavbar";
import SenateCommerceScienceCommittee from "./SenateCommerceScienceCommittee";
import HouseCommitteeOnScienceSpaceAndTechnologyMaterials from "./HouseCommitteeOnScienceSpaceAndTechnologyMaterials";
import GenerateFeedCommittee from "./GenerateFeedCommittee";
var response=''

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


  class PlayUpdateButtonMobile extends React.Component { 
      constructor(props) {
        super(props);
        this.state = {
          isCardView: false,
        }
      } 
      
      render() {
        return (
          <Button  id='navbarUpdateButtonMobile' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
            { this.state.isCardView                                                   
              ? <PauseCircle size={30}   />
              : <PlayCircle size={30}  />
            }
            &nbsp;&nbsp;Update (Beta)
          </Button>
        );
      }
      
    }
  
   
  
  
    
  class PlayUpdateButton extends React.Component { 
    constructor(props) {
      super(props);
      this.state = {
        isCardView: false,
      }
    } 
    
    render() {
      return (
        <Button id='navbarCollectionButtonDesktop' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
          { this.state.isCardView                                                   
            ? <PauseCircle  />
            : <PlayCircle />
          }
          &nbsp;&nbsp;Update (Beta)
        </Button>
      );
    }
    
  }

export const ScienceAndTechnologyMobile = () =>   {
    render()
    
    {
  
  
        const [show, setShow] = useState(true);

        const [modalIsOpen, setModalIsOpen] = useState(false)
       
              
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)

        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
      
        
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
 

{/*Legislative Mobile Carousel*/}

<h5 id='branchHeadingMobile' > <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg> Science & Tech</h5>
{/*onClick={() => setModalIsOpen(true)}*/}




<Col>


  <h5 id='presidentialFeedHeadingMobile'>National Science Foundation</h5>
 {CreateFeedItem('https://www.nsf.gov/rss/rss_www_news.xml', 'nsf', 'National Science Foundation News', 'Executive', 'Science and Technology', NationalScienceFoundation)}


 <div class='courtNewsMobile' id='nsf'></div>



 



</Col>





<Col>


  <h5 id='presidentialFeedHeadingMobile'>NASA</h5>
 {CreateFeedItem('https://www.nasa.gov/rss/dyn/breaking_news.rss', 'nasa', 'NASA', 'Executive', 'Science and Technology', NationalAeronauticsAndSpaceAdministration)}
 <div class='courtNewsMobile' id='nasa'></div>

 



</Col>




<Col>


  <h5 id='presidentialFeedHeadingMobile'>NASA JPL News</h5>
 {CreateFeedItem('https://www.jpl.nasa.gov/feeds/news', 'nasaJPL', 'NASA JPL News', 'N/A', 'Science and Technology', NASAJPLNews)}
 <div class='courtNewsMobile' id='nasaJPL'></div>

 



</Col>




<Col>


  <h5 id='presidentialFeedHeadingMobile'>NIST News</h5>
 {CreateFeedItem('https://www.nist.gov/news-events/news/rss.xml', 'nist', 'NIST News', 'N/A', 'Science and Technology', NISTNews)}
 <div class='courtNewsMobile' id='nist'></div>

 



</Col>




<Col>


  <h5 id='presidentialFeedHeadingMobile'>Government Accountability Office Science and Technology</h5>
 {CreateFeedItem('https://www.gao.gov/rss/topic/Science_and_Technology', 'gaoScienceAndTech', 'Government Accountability Office Science and Technology', 'N/A', 'Science and Technology', GAOScienceAndTech)}
 <div class='courtNewsMobile' id='gaoScienceAndTech'></div>

 



</Col>




<Col>


  <h5 id='presidentialFeedHeadingMobile'>House Committee on Science, Space, and Technology</h5>
 {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SY00', 'committeeOnScienceSpaceAndTech', 'House Committee on Science, Space, and Technology', 'N/A', 'Science and Technology', HouseCommitteeOnScienceSpaceAndTechnology)}


 <div class='courtNewsMobile' id='committeeOnScienceSpaceAndTech'></div>

 



</Col>

<Col>
                    
                    <h5 id='presidentialFeedHeadingMobile'>House Committee on Science, Space, and Technology Materials</h5>
                            <div class='courtNewsMobile'id='houseCommitteeOnScienceSpaceAndTechnologyMaterials'></div>
                           
                            {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Science,%20Space,%20and%20Technology&chamber=HOUSE', 
    'houseCommitteeOnScienceSpaceAndTechnologyMaterials', 
    'House Committee on Science, Space, and Technology Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
        
                        </Col>

<Col>
              
              <h5 id='presidentialFeedHeadingMobile'>Senate Committee on Commerce, Science, and Transportation Materials</h5>
                      <div class='courtNewsMobile'id='senateCommerceScienceAndTransportationCommittee'></div>
                
                      {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Commerce,%20Science,%20and%20Transportation&chamber=SENATE', 
    'senateCommerceScienceAndTransportationCommittee', 
    'Senate Committee on Commerce, Science, and Transportation', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
              
              </Col>





<Col>

            </Col>

            <Navbar id='navbarMobileBottom' fixed="bottom">  

          
 


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>




       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>



</>
)
}}
export default ScienceAndTechnologyMobile;


