
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NationalScienceFoundation from './NationalScienceFoundation';
import CDCNewsroom from './CDCNewsroom';
import Outbreaks from './Outbreaks'
import TravelNotices from './TravelNotices';
import FoodRecalls from './FoodRecalls'
import FoodSafety from './FoodSafety'
import MedWatch from './MedWatch'
import HealthyLivingFeatures from './HealthyLivingFeatures'
import PreventingChronicDisease from './PreventingChronicDisease'
import Covid19 from './Covid19'
import NovelCoronavirus from './NovelCoronavirus'
import VaccineUpdates from './VaccineUpdates'
import SeasonalFlu from './SeasonalFlu'
import {createDepartmentOfHealthAndHumanServices, 
  createCDCNewsroom, createCDCOutbreaks, 
  createCDCTravelNotices, createFoodSafety, 
  createFoodRecalls, createMedWatch, 
  createHealthyLivingFeatures, 
  createPreventingChronicDiseases, 
  createCovid19, createNovelCoronavirus, 
  createVaccineUpdates, createSeasonalFlu, Speak, CreateFeedItem, CreateFeedAPI} from "./Functions";
import DesktopNavbar from './DesktopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import SenateHealthEducationLaborCommittee from './SenateHealthEducationLaborCommittee';
import GenerateFeed from './GenerateFeed';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


 



//https://evening-plains-21303.herokuapp.com/











export const HealthMobile = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)

  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
  const [noaaModalIsOpen, setNOAAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
  const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
  const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
  const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
  const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
  const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
  const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
  const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
  const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
  const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
  const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)

  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  return(
    <>

      


<MobileTopNavbar/>


        <div id='homebg'>
    

            <h5 id='branchHeadingMobile' ><FileMedicalFill></FileMedicalFill>Health</h5>

              <Col>


               <h5 id='presidentialFeedHeadingMobile'>Department of Health and Human Services</h5>



                  <div class='courtNewsMobile' id='healthAndHumanServicesDepartment'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at', 'healthAndHumanServicesDepartment', 
                            'Department of Health and Human Services', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at'
                              name='Department of Health and Human Services' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>
                </Col>

                <Col>

<h5 id='presidentialFeedHeadingMobile'>Senate Committee on Health, Education, Labor, and Pension Materials Materials</h5>
                      <div class='courtNewsMobile'id='senateHealthEducationLaborAndPensionsCommittee'></div>
                
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Health,%20Education,%20Labor,%20and%20Pensions.xml', 'senateHealthEducationLaborAndPensionsCommittee', 'Senate Committee on Health, Education, Labor, and Pensions', 'Legislative', 'N/A', SenateHealthEducationLaborCommittee)}



</Col>

         
<Modal id='departmentOfStateModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
  <DepartmentOfHealthAndHumanServices />
</Modal>



           
  
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>CDC Newsroom</h5>


     

                  <div class='courtNewsMobile' id='cdcNewsroom'></div>

                  {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/cdc_newsroom.xml', 'cdcNewsroom', 'CDC Newsroom', 'N/A', 'Health', CDCNewsroom)}



                </Col>


          
<Modal id='departmentOfStateModal' show={cdcNewsroomModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCDCNewsroomModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>CDC Newsroom</h3></Modal.Header>
  <CDCNewsroom />
</Modal>


              <Col>


               <h5 id='presidentialFeedHeadingMobile'>CDC Outbreaks</h5>


        

                  <div class='courtNewsMobile' id='outbreaks'></div>

                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/285676.rss', 'outbreaks', 'CDC Outbreaks', 'N/A', 'Health', Outbreaks)}


                </Col>


              
<Modal id='departmentOfStateModal' show={outbreaksModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOutbreaksModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>CDC Outbreaks</h3></Modal.Header>
  <Outbreaks />
</Modal>


    
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>CDC Travel Notices</h5>



                  <div class='courtNewsMobile' id='travelNotices'></div>

                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/285740.rss', 'travelNotices', 'CDC Travel Notices', 'N/A', 'Health', TravelNotices)}


                </Col>


<Modal id='departmentOfStateModal' show={travelNoticesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTravelNoticesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>CDC Travel Notices</h3></Modal.Header>
  <TravelNotices />
</Modal>



              <Col>


              <h5 id='presidentialFeedHeadingMobile'>Food Safety</h5>


        
                  <div class='courtNewsMobile' id='foodSafety'></div>

                  {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/food_recalls.xml', 'foodSafety', 'Food Safety', 'N/A', 'Health', FoodSafety)}

                </Col>


          
<Modal id='departmentOfStateModal' show={foodSafetyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodSafetyModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Food Safety</h3></Modal.Header>
  <FoodSafety/>

</Modal>



       
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>Food Recalls</h5>




                  <div class='courtNewsMobile' id='foodRecalls'></div>

                  {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/food_recall.xml		', 'foodRecalls', 'Food Recalls', 'N/A', 'Health', FoodRecalls)}


                </Col>


            
<Modal id='departmentOfStateModal' show={foodRecallsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodRecallsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Food Recalls</h3></Modal.Header>
  <FoodRecalls />
</Modal>


    
              <Col>


               <h5 id='presidentialFeedHeadingMobile'>Med Watch</h5>



                  <div class='courtNewsMobile' id='medWatch'></div>

                  {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/med_watch.xml', 'medWatch', 'Med Watch', 'N/A', 'Health', MedWatch)}

                </Col>


          
<Modal id='departmentOfStateModal' show={medWatchModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMedWatchModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Med Watch</h3></Modal.Header>
  <MedWatch/>
</Modal>



      
             <Col>


              <h5 id='presidentialFeedHeadingMobile'>Healthy Living Features</h5>


                  <div class='courtNewsMobile' id='healthyLivingFeatures'></div>

                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/132036.rss', 'healthyLivingFeatures', 'Healthy Living Features', 'N/A', 'Health', HealthyLivingFeatures)}


                </Col>


               
<Modal id='departmentOfStateModal' show={healthyLivingFeaturesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthyLivingFeaturesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Healthy Living Features</h3></Modal.Header>
  <HealthyLivingFeatures/>
</Modal>


            
              <Col>


               <h5 id='presidentialFeedHeadingMobile'>Preventing Chronic Disease</h5>



                  <div class='courtNewsMobile' id='preventingChronicDisease'></div>

                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/322083.rss', 'preventingChronicDisease', 'Preventing Chronic Disease', 'N/A', 'Health', PreventingChronicDisease)}


                </Col>



<Modal id='departmentOfStateModal' show={preventingChronicDiseaseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPreventingChronicDiseaseModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Preventing Chronic Disease</h3></Modal.Header>
  <PreventingChronicDisease/>
</Modal>

          
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>Covid-19</h5>


                  <div class='courtNewsMobile' id='covid19'></div>

                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/404952.rss', 'covid19', 'Covid-19 ', 'N/A', 'Health', Covid19)}


                </Col>


         
<Modal id='departmentOfStateModal' show={covid19ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCovid19ModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Covid-19</h3></Modal.Header>
  <Covid19/>
</Modal>


           
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>Novel Coronavirus</h5>



                  <div class='courtNewsMobile' id='novelCoronavirus'></div>
                 
                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/403372.rss', 'novelCoronavirus', 'Novel Coronavirus', 'N/A', 'Health', NovelCoronavirus)}


                </Col>


<Modal id='departmentOfStateModal' show={novelCoronavirusModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNovelCoronavirusModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Novel Coronavirus</h3></Modal.Header>
  <NovelCoronavirus/>
</Modal>



            
             <Col>


              <h5 id='presidentialFeedHeadingMobile'>Vaccine Updates</h5>


                  <div class='courtNewsMobile' id='vaccineUpdates'></div>

                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/415826.rss', 'vaccineUpdates', 'Vaccine Updates', 'N/A', 'Health', VaccineUpdates)}

                </Col>


          
<Modal id='departmentOfStateModal' show={vaccineUpdatesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setVaccineUpdatesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Vaccine Updates</h3></Modal.Header>
  <VaccineUpdates/>
</Modal>


       
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>Seasonal Flu</h5>



                  <div class='courtNewsMobile' id='seasonalFlu'></div>

                  {CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/277692.rss', 'seasonalFlu', 'Seasonal Flu', 'N/A', 'Health', SeasonalFlu)}


                </Col>


             
<Modal id='departmentOfStateModal' show={seasonalFluModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSeasonalFluModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Seasonal Flu</h3></Modal.Header>
  <SeasonalFlu/>
</Modal>




  
           


           




          
          
        
          <Col>

            </Col>
            <Navbar id='navbarMobileBottom' fixed="bottom">  

          
 


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>




       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>




             
              
    </>
  )
}
 }



  






export default HealthMobile;