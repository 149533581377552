import React, { useState, useEffect } from 'react';
import { Image, Navbar, Dropdown, Button, Col, Modal, Card } from 'react-bootstrap';
import { Link45deg, HeartFill } from 'react-bootstrap-icons';
import MobileOffCanvas from './MobileOffCanvas';
import MobileBackButton from './MobileBackButton';

import { CreateFeedAPIDepartmentUK, CreateFeedAPIDepartmentUS, CreateFeedItem, CreateFeedItemFR } from './Functions';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import MobileTopNavbar from './MobileTopNavbar';
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopNavbar from './DesktopNavbar';
import { Link } from 'react-router-dom';



const AgencyTemplate = ({ imageName, agencyName, officialURL, missionStatement, rssURL, feedName,topic  }) => {
  


  const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false);
  const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false);
  const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false);
  const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false);
  const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false);
  const [showBioModal, setBioModal] = useState(false);
  const branch = "all";
  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)


  return (
    <>
<MobileView>
<MobileTopNavbar/>
      <div id='homebg'>
        <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src={imageName} />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile">{agencyName}</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open(officialURL, "_blank") }}>
                  Official Site
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
            Mission Statement: {missionStatement}
          </div>
        </div>

        <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>{feedName}</h5>

<div class='courtNewsMobile' id='agency'></div>
 

  {CreateFeedAPIDepartmentUS(rssURL, 'agency', feedName, 'Executive', topic, 'Doc', GenerateFeedDepartment)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                  <GenerateFeedDepartment   url={rssURL}
                                name={feedName} branch='Executive' topic={topic} buttonName='Doc'  />
                </Modal>
        <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288')
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
       
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>
          </MobileView>  

     
          <BrowserView>
        <div id='homebg'>
        <DesktopNavbar/>
               <br></br>
<div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>
   {imageName && (
     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src={imageName} />
   </div>
   )}

   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3 >{feedName}</h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span>{missionStatement}</span>
<br></br>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>
{officialURL && (
        <Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open(officialURL, "_blank");
        }}>
          {officialURL}
          </Link>
)}
</div>
  </div>

     </div>
     </div>


     </div>
     <br></br>
     <br></br>
     <br></br>
     <Card.Body  id='cardBodyRep' ><h3>Documents</h3></Card.Body>

     <div  id='agency'></div>
 

  {CreateFeedAPIDepartmentUS(rssURL, 'agency', feedName, 'Executive', topic, 'Doc', GenerateFeedDepartment)}


              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                  <GenerateFeedDepartment   url={rssURL}
                                name={feedName} branch='Executive' topic={topic} buttonName='Doc'  />
                </Modal>
           </div>
           </div>
            </BrowserView>
          
          
          
      
          </>
  )
                    
                    }

 



  






export default AgencyTemplate;

