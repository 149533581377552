
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab } from 'react-bootstrap';
import { ArrowsAngleExpand,ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, People,  Grid, ThreeDots, Stack, List, Files,FolderPlus,FolderCheck,Folder, House } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import Home from './Home';
import LegislativePage from './LegislativePage';
import LegislativeBranch from './LegislativeBranch';
import ExecutiveBranch from './ExecutiveBranch';
import JudicalBranch from './JudicalBranch';


import { Component } from "react";
import Economy from './Economy';
import Environment from './Environment';

import ForeignAffairs from './ForeignAffairs';
import Health from './Health';
import AlabamaHome from './AlabamaHome';
import SomeTestFile from './SomeTestFile'

import AlaskaHome from './AlaskaHome';
import ArizonaHome from './ArizonaHome';
import ArkansasHome from './ArkansasHome';
import { CaliforniaHome } from './CaliforniaHome';
import ColoradoHome from './ColoradoHome';
import ConnecticutHome from './ConnecticutHome';
import { DelawareHome } from './States/DelawareHome';
import {FloridaHome} from './States/FloridaHome'
import {GeorgiaHome} from './States/GeorgiaHome'
import {HawaiiHome} from './States/HawaiiHome'
import IdahoHome from './States/IdahoHome';
import IllinoisHome from './States/IllinoisHome';
import IndianaHome from './States/IndianaHome';
import IowaHome from './States/IowaHome';
import KansasHome from './States/KansasHome';
import KentuckyHome from './States/KentuckyHome';
import LouisianaHome from './States/LouisianaHome';
import MaineHome from './States/MaineHome';
import MarylandHome from  './States/MarylandHome';
import MassachusettsHome from './States/MassachusettsHome';
import MichiganHome from './States/MichiganHome';
import MinnesotaHome from './States/MinnesotaHome';
import MississippiHome from './States/MississippiHome';
import MissouriHome from './States/MissouriHome';
import MontanaHome from './States/MontanaHome';
import NebraskaHome from './States/NebraskaHome';
import NevadaHome from './States/NevadaHome';
import NewHampshireHome from './States/NewHampshireHome';
import NewJerseyHome from './States/NewJerseyHome';
import NewMexicoHome from './States/NewMexicoHome';
import NewYorkHome from './States/NewYorkHome';
import NorthCarolina from './States/NorthCarolinaHome';
import NorthDakota from './States/NorthDakotaHome';
import OhioHome from './States/OhioHome';
import OklahomaHome from './States/OklahomaHome';
import OregonHome from './States/OregonHome';
import PennsylvaniaHome from './States/PennsylvaniaHome';
import RhodeIslandHome from './States/RhodeIslandHome';
import SouthCarolinaHome from './States/SouthCarolinaHome';
import SouthDakotaHome from './States/SouthDakotaHome';
import TennesseeHome from './States/TennesseeHome';
import TexasHome from './States/TexasHome';
import UtahHome from './States/UtahHome';
import VermontHome from './States/VermontHome';
import VirginiaHome from './States/VirginiaHome';
import WashingtonHome from './States/WashingtonHome';
import WestVirginiaHome from './States/WestVirginiaHome';
import WisconsinHome from './States/WisconsinHome';
import WyomingHome from './States/WyomingHome';
import DesktopNavbar from './DesktopNavbar'
import StatesNavbar from './StatesNavbar';
import Energy from './Energy';
import Election from './Election';
import CollectionHome from './CollectionHome';
import { ScienceAndTechnology } from './ScienceAndTechnology';
import ExecutiveOffice from './ExecutiveOffice';
import HousePage from './HousePage2';
import Senate from './Senate';
import Defense from './Defense';
import HomelandSecurity from './HomelandSecurity';
import DepartmentOfStateDesk from './DepartmentOfStateDesk';
import DepartmentOfTreasuryDesk from './DepartmentOfTreasuryDesk';
import DepartmentOfJusticeDesk from './DepartmentOfJusticeDesk';
import DepartmentOfTransportationDesk from './DepartmentOfTransportationDesk';
import DepartmentOfInteriorDesk from './DepartmentOfInteriorDesk';
import DepartmentOfAgricultureDesk from './DepartmentOfAgricultureDesk';
import DepartmentOfCommerceDesk from './DepartmentOfCommerceDesk';
import DepartmentOfHouseAndUrbanDevelopmentDesk from './DepartmentOfHouseAndUrbanDevelopmentDesk';
import DepartmentOfEducationDesk from './DepartmentOfEducationDesk';
import DepartmentOfEnergyDesk from './DepartmentOfEnergyDesk';
import DepartmentOfVeteransAffairsDesk from './DepartmentOfVeteransAffairsDesk';
import DepartmentOfLaborDesk from './DepartmentOfLaborDesk';
import DepartmentOfHealthAndHumanServicesDocs from './DepartmentOfHealthAndHumanServicesDocs';
import DepartmentOfHealthDesk from './DepartmentOfHealthDesk';
import CanadaHome from './Canada/CanadaHome';
import CanadaExecutive from './Canada/Executive/CanadaExecutive';
import CanadaJudicial from './Canada/Judicial/CanadaJudicial';
import CanadaLegislative from './Canada/Legislative/CanadaLegislative';
import CanadaEconomy from './Canada/Topics/CanadaEconomy';
import CanadaEnvironment from './Canada/Topics/CanadaEnvironment';
import CanadaForeignAffairs from './Canada/Topics/CanadaForeignAffairs';
import CanadaScienceAndTech from './Canada/Topics/CanadaScienceAndTech';
import Alberta from './Canada/Provinces/Alberta';
import BritishColumbia from './Canada/Provinces/BritishColumbia';
import Manitoba from './Canada/Provinces/Manitoba';
import NewBrunsiwck from './Canada/Provinces/NewBrunsiwck';
import NewfoundlandAndLabrador from './Canada/Provinces/NewfoundlandAndLabrador';
import NorthwestTerritories from './Canada/Provinces/NorthwestTerritories';
import NovaScotia from './Canada/Provinces/NovaScotia';
import Ontario from './Canada/Provinces/Ontario';
import PrinceEdwardIsland from './Canada/Provinces/PrinceEdwardIsland';
import Quebec from './Canada/Provinces/Quebec';
import Saskatchewan from './Canada/Provinces/Saskatchewan';
import Yukon from './Canada/Provinces/Yukon';
import Nunavut from './Canada/Provinces/Nunavut';
import HomeCountrySelectMobile from './HomeCountrySelectMobile';
import MexicoHome from './Mexico/MexicoHome';
import MexicoLegislative from './Mexico/Legislative/MexicoLegislative';
import MexicoExecutive from './Mexico/Executive/MexicoExecutive';
import MexicoJudicial from './Mexico/Judicial/MexicoJudicial';
import MexicoEconomy from './Mexico/Topics/MexicoEconomy';
import MexicoEnvironment from './Mexico/Topics/MexicoEnvironment';
import MexicoForeignAffairs from './Mexico/Topics/MexicoForeignAffairs';
import MexicoHealth from './Mexico/Topics/MexicoHealth';
import AIMobile from './AIChat';
import AI from './AI';
import UKExecutive from './UK/Executive/UKExecutive';
import UKJudicial from './UK/Judicial/UKJudicial';
import UKLegislative from './UK/Legislative/UKLegislative';
import UKEconomy from './UK/Topics/Economy/UKEconomy';
import UKEnvironment from './UK/Topics/Environment/UKEnvironment';
import UKForeignAffairs from './UK/Topics/Foreign Affairs/UKForeignAffairs';
import UKHealth from './UK/Topics/Health/UKHealth';
import UKScienceAndTech from './UK/Topics/Science and Tech/UKScienceAndTech';
import UKHome from './UK/UKHome';
import Wales from './UK/Kingdoms/Wales';
import England from './UK/Kingdoms/England';
import NorthernIreland from './UK/Kingdoms/NorthernIreland';
import Scotland from './UK/Kingdoms/Scotland';
import GovGlanceSearch from './GovGlanceSearch';
import NewAPI from './NewAPI';
import Infrastructure from './Infrastructure';
import EnergyPage from './EnergyPage';
import DefensePage from './DefensePage';
import CanadaDefense from './Canada/Topics/CanadaDefense';
import CanadaEnergy from './Canada/Topics/CanadaEnergy';
import JusticePage from './JusticePage';
import MexicoDefense from './Mexico/Topics/MexicoDefense';
import MexicoInfrastructure from './Mexico/Topics/MexicoInfrastructure';
import UKDefense from './UK/Topics/Defense/UKDefense';
import UKEnergy from './UK/Topics/Energy/UKEnergy';
import UKInfrastructure from './UK/Topics/Infrastructure/UKInfrastructure';
import UKJustice from './UK/Topics/Justice/UKJustice';
import TestSearch from './TestSearch';
import FeedPage from './FeedPage';
import NavbarSearchButton from './NavbarSearchButton';
import SearchPage from './TestSearchPage';
import Represenatives from './Represenatives';
import RepresenativesTest from './Represenatives Checkpoint';
import RepresentativePage from './RepresenativePage';
import MemberDetails from './MemberDetails';
import MemberItem from './MemberItem';
import RepresenativesTestTest from './RepresenativesTestTest';
import Presidents from './Presidents';
import PresidentPage from './PresidentPage';
import GenerateFeedRecent from './GenerateFeedRecent';
import HouseOfRepsMembers from './HouseOfRepsMembers';
import SenateMembers from './SenateMembers';
import PresidentDisplayOnPage from './PresidentDisplayOnPage';
import VicePresidents from './VicePresidents';
import VicePresidentPage from './VicePresidentPage';
import Justices from './Justices';
import USJusticePage from './USJusticePage';
import CacheTest from './CacheTest';
import GenerateFeedPage from './GenerateFeedPage';
import GenerateFeedPageCanada from './GenerateFeedPageCanada';
import GenerateFeedPageMexico from './GenerateFeedPageMexico';
import GenerateFeedPageBillUpdates from './GenerateFeedPageBillUpdates';
import AgencyTemplate from './AgencyTemplate';
import WhiteHousePage from './WhiteHousePage';
import TestPage420 from './TestPage420';
import GenerateFeedRecentAll from './GenerateFeedRecentAll';
import FederalElectionCommissionPage from './FederalElectionCommissionPage';
import ElectionAssistancePage from './ElectionAssistancePage';
import StateElectionPage from './StateElectionPage';
import Election2024 from './Election2024';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
import CandidatePage from './CandidatePage';
import CandidateJSONTable from './CandidateJSONTable';
import UKDepartmentTemplate from './UKDepartmentTemplate';
import About from './About';
import Privacy from './Privacy';
import CanadaAgencyTemplate from './CanadaAgencyTemplate';
import UKAgencyTemplate from './UKAgencyTemplate';
import GenerateFeedPageUKReal from './GenerateFeedPageUKReal';
import USSupremeCourtPage from './USSupremeCourtPage';
import { CreateFeedAPI } from './Functions';
import GenerateFeed from './GenerateFeed copy 4';
import FeedPageTest from './FeedPageTest';
import FederalReservePage from './FederalReservePage';
import NavbarTest from './NavbarTest';

import GenerateFeedOpinions from './GenerateFeedOpinions';
import TemplatePageHome from './TemplatePageHome';
import FeedPageById from './FeedPageById';
import FeedDetailsPage from './FullDeatailsPage';
      
const feeds = [
  {
    apiURL: "https://api.govglance.org/posts/recent?limit=1&schema=united_states_of_america",
    divID: "recent",
    feedName: "Recents",
    feedBranch: "N/A",
    feedTopic: "N/A",
    feedButtonName: "Document",
    FeedFunction: GenerateFeedRecent
  },
  {
    apiURL: "&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at",
    divID: "bills",
    feedName: "Bill Updates",
    feedBranch: "Legislative",
    feedTopic: "N/A",
    feedButtonName: "Bill",
    FeedFunction: GenerateFeed
  },
  {
    apiURL: "&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at",
    divID: "opinions",
    feedName: "Supreme Court Opinions",
    feedBranch: "Judicial",
    feedTopic: "N/A",
    feedButtonName: "Document",
    FeedFunction: GenerateFeedOpinions
  }
];

require('dotenv').config();





function App()   {

  return (
    <>

      <Router>
        <Switch>
        <Route exact path='/' component={HomeCountrySelectMobile} />
          <Route exact path='/us' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/us/legislative' component={LegislativeBranch}  />
          <Route exact path ='/us/house' component={HousePage}/>
          <Route exact path ='/us/senate' component={Senate}/>
          <Route exact path='/us/executive' component={ExecutiveBranch} />
          <Route exact path='/us/whitehouse' component={WhiteHousePage} />
          <Route exact path='/us/judicial' component={JudicalBranch} />
          <Route exact path='/us/defense' component={DefensePage} />
          <Route exact path='/us/economy' component={Economy} />
          <Route exact path='/us/federalreserve' component={FederalReservePage} />
          <Route exact path='/us/elections' component={Election} />
          <Route exact path='/us/energy' component={EnergyPage} />
          <Route exact path='/us/environment' component={Environment} />
          <Route exact path='/us/foreignaffairs' component={ForeignAffairs} />
          <Route exact path='/us/health' component={Health} />
          <Route exact path='/us/infrastructure' component={Infrastructure} />
          <Route exact path='/us/justice' component={JusticePage} />
          <Route exact path ='/us/science&tech' component={ScienceAndTechnology}/>
          <Route exact path ='/us/executiveoffice' component={ExecutiveOffice}/>
          <Route exact path ='/us/fec' component={FederalElectionCommissionPage}/>
          <Route exact path ='/us/eac' component={ElectionAssistancePage}/>

 

          <Route exact path ='/us/feed' component={GenerateFeedPage}/>
          <Route  path='/canada/feed' component={GenerateFeedPageCanada }/>
          <Route  path='/mexico/feed' component={GenerateFeedPageMexico }/>
          <Route  path='/uk/feed' component={GenerateFeedPageUKReal }/>
          <Route exact path ='/us/departmentofdefense' component={Defense}/>
          <Route exact path ='/us/departmentofhomeland' component={HomelandSecurity}/>
          <Route exact path ='/us/departmentofstate' component={DepartmentOfStateDesk}/>
          <Route exact path ='/us/departmentoftreasury' component={DepartmentOfTreasuryDesk}/>
          <Route exact path ='/us/departmentofjustice' component={DepartmentOfJusticeDesk}/>
          <Route exact path ='/us/departmentoftransportation' component={DepartmentOfTransportationDesk}/>
          <Route exact path ='/us/departmentoftheinterior' component={DepartmentOfInteriorDesk}/>
          <Route exact path ='/us/departmentofagriculture' component={DepartmentOfAgricultureDesk}/>
          <Route exact path ='/us/departmentofcommerce' component={DepartmentOfCommerceDesk}/>
          <Route exact path ='/us/departmentofhousingandurbandevelopment' component={DepartmentOfHouseAndUrbanDevelopmentDesk}/>
          <Route exact path ='/us/departmentofeducation' component={DepartmentOfEducationDesk}/>
          <Route exact path ='/us/departmentofenergy' component={DepartmentOfEnergyDesk}/>
          <Route exact path ='/us/departmentofveteransaffairs' component={DepartmentOfVeteransAffairsDesk}/>
          <Route exact path ='/us/departmentoflabor' component={DepartmentOfLaborDesk}/>
          <Route exact path ='/us/departmentofhealthandhumanservices' component={DepartmentOfHealthDesk}/>


          <Route
          exact
          path='/testpage'
          render={(props) => <TemplatePageHome feeds={feeds} />}
        />
<Route
  path="/feed/:schema/:table/:id"
  component={FeedDetailsPage}
/>
<Route
  path="/feed/:schema?/:table?"
  render={(props) => {
    const { apiURL = '', feedName = '', feedBranch = '', feedTopic = '', feedButtonName = '' } = props.location.state || {};

    // Extract schema and table from the URL params if present
    const { schema, table } = props.match.params;

    return (
      <FeedPageTest
        {...props}
        apiRequest={{
          // Use location.state if present, otherwise fallback to schema and table from the URL
          url: apiURL || `&skip=0&schema=${schema}&table=${table}&order_by=created_at`,
          name: feedName,
          branch: feedBranch,
          topic: feedTopic,
          buttonName: feedButtonName,
        }}
      />
    );
  }}
/>


          <Route exact path ='/ai' component={AI}/>
          <Route path={['/search/:country', '/search']} component={GovGlanceSearch} />
          <Route exact path='/us/presidents' component={Presidents }/>
          <Route exact path='/us/vicepresidents' component={VicePresidents }/>
          <Route exact path='/us/representatives' component={RepresenativesTestTest }/>
          <Route path="/us/representative/:bio_guide_id" exact component={RepresentativePage} />
          <Route path="/us/president/:presidentNumber" exact component={PresidentPage} />
          <Route  path='/us/vicepresident/:name' component={VicePresidentPage }/>
          <Route path="/us/houseofreps" exact component={HouseOfRepsMembers} />
          <Route path="/us/senators" exact component={SenateMembers} />
          <Route path="/us/supremecourt" exact component={USSupremeCourtPage} />
          <Route path="/us/justices" exact component={Justices} />
          <Route  path='/us/justice/:name' component={USJusticePage }/>
          <Route path="/us/candidate/:name" exact component={CandidatePage} />
  
          <Route path="/test" component={TestPage420} />
          <Route exact path='/agency' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfDefense.png' 
            agencyName='Animal and Plant Health Inspection Service' 
            officialURL='youtube.com' 
            missionStatement='YOOOOOO' 
            rssURL='agency=Animal%20and%20Plant%20Health%20Inspection%20Service' 
            feedName='Animal and Plant Health Inspection Service Documents' 
            topic='Agriculture' 
          />
        )} />
          <Route exact path='/us/alabama' component={AlabamaHome} />
          <Route exact path='/us/alaska' component={AlaskaHome}/>
          <Route exact path ='/us/arizona' component={ArizonaHome}/>
          <Route exact path ='/us/arkansas' component={ArkansasHome}/>
          <Route exact path ='/us/california' component={CaliforniaHome}/>
         <Route exact path ='/us/colorado' component={ColoradoHome}/>
         <Route exact path ='/us/connecticut' component={ConnecticutHome}/>
         <Route exact path ='/us/delaware' component={DelawareHome}/>
         <Route exact path ='/us/florida' component={FloridaHome}/>
         <Route exact path ='/us/georgia' component={GeorgiaHome}/>
         <Route exact path ='/us/hawaii' component={HawaiiHome}/>
         <Route exact path ='/us/idaho' component={IdahoHome}/>
         <Route exact path ='/us/illinois' component={IllinoisHome}/>
         <Route exact path ='/us/indiana' component={IndianaHome}/>
       
         <Route exact path ='/us/iowa' component={IowaHome}/>
         <Route exact path ='/us/kansas' component={KansasHome}/>
         <Route exact path ='/us/kentucky' component={KentuckyHome}/>
         <Route exact path ='/us/louisiana' component={LouisianaHome}/>
         <Route exact path ='/us/maine' component={MaineHome}/>
         <Route exact path ='/us/maryland' component={MarylandHome}/>
         <Route exact path ='/us/massachusetts' component={MassachusettsHome}/>
         <Route exact path ='/us/michigan' component={MichiganHome}/>
         <Route exact path ='/us/minnesota' component={MinnesotaHome}/>
         <Route exact path ='/us/mississippi' component={MississippiHome}/>
         <Route exact path ='/us/missouri' component={MissouriHome}/>
         <Route exact path ='/us/montana' component={MontanaHome}/>
         <Route exact path ='/us/nevada' component={NevadaHome}/>
         <Route exact path ='/us/nebraska' component={NebraskaHome}/>
         <Route exact path ='/us/newhampshire' component={NewHampshireHome}/>
         <Route exact path ='/us/newjersey' component={NewJerseyHome}/>
         <Route exact path ='/us/newmexico' component={NewMexicoHome}/>
         <Route exact path ='/us/newyork' component={NewYorkHome}/>
         <Route exact path ='/us/northcarolina' component={NorthCarolina}/>
         <Route exact path ='/us/northdakota' component={NorthDakota}/>
         <Route exact path ='/us/ohio' component={OhioHome}/>
         <Route exact path ='/us/oklahoma' component={OklahomaHome}/>
         <Route exact path ='/us/oregon' component={OregonHome}/>
         <Route exact path ='/us/pennsylvania' component={PennsylvaniaHome}/>
         <Route exact path ='/us/rhodeisland' component={RhodeIslandHome}/>
         <Route exact path ='/us/southcarolina' component={SouthCarolinaHome}/>
         <Route exact path ='/us/southdakota' component={SouthDakotaHome}/>
         <Route exact path ='/us/tennessee' component={TennesseeHome}/>
         <Route exact path ='/us/texas' component={TexasHome}/>
         <Route exact path ='/us/utah' component={UtahHome}/>
         <Route exact path ='/us/vermont' component={VermontHome}/>
         <Route exact path ='/us/virginia' component={VirginiaHome}/>
         <Route exact path ='/us/washington' component={WashingtonHome}/>
         <Route exact path ='/us/westvirginia' component={WestVirginiaHome}/>
         <Route exact path ='/us/wisconsin' component={WisconsinHome}/>
         <Route exact path ='/us/wyoming' component={WyomingHome}/>





         <Route
          exact
          path='/us/:stateName/elections'
          component={StateElectionPage}
        />

<Route exact path ='/us/election2024' component={Election2024}/>

        <Route exact path ='/collection' component={CollectionHome}/>


        <Route exact path ='/canada' component={CanadaHome}/>
        <Route exact path ='/canada/executive' component={CanadaExecutive}/>
        <Route exact path ='/canada/judicial' component={CanadaJudicial}/>
        <Route exact path ='/canada/legislative' component={CanadaLegislative}/>
     
        <Route exact path ='/canada/defense' component={CanadaDefense}/>
        <Route exact path ='/canada/economy' component={CanadaEconomy}/>
        <Route exact path ='/canada/energy' component={CanadaEnergy}/>
        <Route exact path ='/canada/environment' component={CanadaEnvironment}/>
        <Route exact path ='/canada/foreignaffairs' component={CanadaForeignAffairs}/>
        <Route exact path ='/canada/science&tech' component={CanadaScienceAndTech}/>


        <Route exact path ='/canada/alberta' component={Alberta}/>
        <Route exact path ='/canada/britishcolumbia' component={BritishColumbia}/>
        <Route exact path ='/canada/manitoba' component={Manitoba}/>
        <Route exact path ='/canada/newbrunswick' component={NewBrunsiwck}/>
        <Route exact path ='/canada/newfoundland&labrador' component={NewfoundlandAndLabrador}/>
        <Route exact path ='/canada/northwestterritories' component={NorthwestTerritories}/>
        <Route exact path ='/canada/novascotia' component={NovaScotia}/>
        <Route exact path ='/canada/nunavut' component={Nunavut}/>
        <Route exact path ='/canada/ontario' component={Ontario}/>
        <Route exact path ='/canada/princeedwardisland' component={PrinceEdwardIsland}/>
        <Route exact path ='/canada/quebec' component={Quebec}/>
        <Route exact path ='/canada/saskatchewan' component={Saskatchewan}/>
        <Route exact path ='/canada/yukon' component={Yukon}/>

        <Route exact path='/canada/revenue-agency' render={(props) => (
          <CanadaAgencyTemplate
            {...props} 
            imageName='/canadaExecutive.png' 
            agencyName='Canada Revenue Agency' 
            officialURL='https://www.canada.ca/en/revenue-agency.html' 
            missionStatement='Administer tax, benefits, and related programs, and ensure compliance on behalf of governments across Canada, thereby contributing to the ongoing economic and social well-being of Canadians.' 
            rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Revenue%20Agency' 
            feedName='Canada Revenue Agency' 
            topic='Economy' 
          />
        )} />

<Route exact path='/canada/impact-assessment-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Impact Assessment Agency of Canada'
    officialURL='https://www.canada.ca/en/impact-assessment-agency.html'
    missionStatement='Conduct impact assessments to contribute to informed decision-making on major projects, in support of sustainable development in Canada.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Impact%20Assessment%20Agency%20of%20Canada'
    feedName='Impact Assessment Agency of Canada'
    topic='Environment'
  />
)} />

<Route exact path='/canada/food-inspection-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Canadian Food Inspection Agency'
    officialURL='https://www.inspection.gc.ca/'
    missionStatement='Safeguard food, animals, and plants, which enhances the health and well-being of Canada’s people, environment, and economy.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Food%20Inspection%20Agency'
    feedName='Canadian Food Inspection Agency'
    topic='Health'
  />
)} />

<Route exact path='/canada/atlantic-opportunities-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Atlantic Canada Opportunities Agency'
    officialURL='https://www.canada.ca/en/atlantic-canada-opportunities.html'
    missionStatement='Create opportunities for economic growth in Atlantic Canada by helping businesses become more competitive, innovative, and productive.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Atlantic%20Canada%20Opportunities%20Agency'
    feedName='Atlantic Canada Opportunities Agency'
    topic='Economy'
  />
)} />

<Route exact path='/canada/feddev-ontario' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Federal Economic Development Agency for Southern Ontario'
    officialURL='https://www.feddevontario.gc.ca/'
    missionStatement='Support economic growth and job creation in Southern Ontario by providing resources and funding to businesses and communities.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Federal%20Economic%20Development%20Agency%20for%20Southern%20Ontario'
    feedName='Federal Economic Development Agency for Southern Ontario'
    topic='Economy'
  />
)} />

<Route exact path='/canada/border-services-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Canada Border Services Agency'
    officialURL='https://www.cbsa-asfc.gc.ca/'
    missionStatement='Provide integrated border services that support national security and public safety priorities and facilitate the free flow of persons and goods.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Border%20Services%20Agency'
    feedName='Canada Border Services Agency'
    topic='Defense'
  />
)} />

<Route exact path='/canada/transportation-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Canadian Transportation Agency'
    officialURL='https://www.otc-cta.gc.ca/'
    missionStatement='Oversee the national transportation system to ensure it is accessible, efficient, and serves the needs of all Canadians.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Transportation%20Agency'
    feedName='Canadian Transportation Agency'
    topic='Infrastructure'
  />
)} />

<Route exact path='/canada/northern-economic-development' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Canadian Northern Economic Development Agency'
    officialURL='https://www.cannor.gc.ca/'
    missionStatement='Support economic development in Canada’s Northern territories, fostering growth and creating opportunities for Northerners.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Northern%20Economic%20Development%20Agency'
    feedName='Canadian Northern Economic Development Agency'
    topic='Economy'
  />
)} />

<Route exact path='/canada/financial-consumer-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Financial Consumer Agency of Canada'
    officialURL='https://www.canada.ca/en/financial-consumer-agency.html'
    missionStatement='Protect and inform consumers of financial products and services, and promote fair and competitive financial marketplaces in Canada.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Financial%20Consumer%20Agency%20of%20Canada'
    feedName='Financial Consumer Agency of Canada'
    topic='Economy'
  />
)} />

<Route exact path='/canada/public-health-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Public Health Agency of Canada'
    officialURL='https://www.canada.ca/en/public-health.html'
    missionStatement='Promote and protect the health of Canadians through leadership, partnership, innovation, and action in public health.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Public%20Health%20Agency%20of%20Canada'
    feedName='Public Health Agency of Canada'
    topic='Health'
  />
)} />

<Route exact path='/canada/space-agency' render={(props) => (
  <CanadaAgencyTemplate
    {...props}
    imageName='/canadaExecutive.png'
    agencyName='Canadian Space Agency'
    officialURL='https://www.asc-csa.gc.ca/'
    missionStatement='Promote the peaceful use and development of space, advance the knowledge of space through science, and ensure that space science and technology provide social and economic benefits for Canadians.'
    rssURL='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Space%20Agency'
    feedName='Canadian Space Agency'
    topic='Science & Tech'
  />
)} />

       
        

        <Route exact path='/mexico' component={MexicoHome}/>
        <Route exact path='/mexico/legislative' component={MexicoLegislative}/>
        <Route exact path='/mexico/executive' component={MexicoExecutive}/>
        <Route exact path='/mexico/judicial' component={MexicoJudicial}/>

        <Route exact path='/mexico/defense' component={MexicoDefense}/>
        <Route exact path='/mexico/economy' component={MexicoEconomy}/>
        <Route exact path='/mexico/environment' component={MexicoEnvironment}/>

        <Route exact path='/mexico/foreignaffairs' component={MexicoForeignAffairs}/>
        <Route exact path='/mexico/health' component={MexicoHealth}/>
        <Route exact path='/mexico/infrastructure' component={MexicoInfrastructure}/>

        <Route exact path='/uk' component={UKHome}/>
        <Route exact path='/uk/executive' component={UKExecutive}/>
        <Route exact path='/uk/judicial' component={UKJudicial}/>
        <Route exact path='/uk/legislative' component={UKLegislative}/>
        <Route exact path='/uk/defense' component={UKDefense}/>
        <Route exact path='/uk/economy' component={UKEconomy}/>
        <Route exact path='/uk/energy' component={UKEnergy}/>
        <Route exact path='/uk/environment' component={UKEnvironment}/>
        <Route exact path='/uk/foreignaffairs' component={UKForeignAffairs}/>
        <Route exact path='/uk/health' component={UKHealth}/>
        <Route exact path='/uk/infrastructure' component={UKInfrastructure}/>
        <Route exact path='/uk/justice' component={UKJustice}/>
        <Route exact path='/uk/science&tech' component={UKScienceAndTech}/>





        



        <Route exact path='/uk/department-for-environment' render={(props) => (
          <UKAgencyTemplate
            {...props} 
            imageName='/ukExecutive.png' 
            agencyName='Department For Environment Food Rural Affairs' 
            officialURL='https://www.gov.uk/government/organisations/department-for-environment-food-rural-affairs' 
            missionStatement='We are here to make our air purer, our water cleaner, our land greener and our food more sustainable. Our mission is to restore and enhance the environment for the next generation, leaving it in a better state than we found it.' 
            rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Environment%20Food%20Rural%20Affairs' 
            feedName='Department For Environment Food Rural Affairs' 
            topic='Environment' 
          />

          


          
        )} />

<Route exact path='/uk/office-of-the-advocate-general-for-scotland' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Office Of The Advocate General For Scotland'
    officialURL='https://www.gov.uk/government/organisations/office-of-the-advocate-general-for-scotland'
    missionStatement='The Office of the Advocate General for Scotland provides legal advice and representation on issues relating to Scotland and the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Advocate%20General%20For%20Scotlandx'
    feedName='Office Of The Advocate General For Scotland'
    topic='N/A'
  />
)} />

<Route exact path='/uk/department-for-culture-media-and-sport' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Culture Media And Sport'
    officialURL='https://www.gov.uk/government/organisations/department-for-culture-media-and-sport'
    missionStatement='The Department for Culture, Media and Sport is responsible for promoting culture, media, and sports in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Culture%20Media%20And%20Sport'
    feedName='Department For Culture Media And Sport'
    topic='Health'
  />
)} />

<Route exact path='/uk/office-of-the-secretary-of-state-for-scotland' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Office Of The Secretary Of State For Scotland'
    officialURL='https://www.gov.uk/government/organisations/office-of-the-secretary-of-state-for-scotland'
    missionStatement='The Office of the Secretary of State for Scotland represents Scotland’s interests within the UK Government.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Scotland'
    feedName='Office Of The Secretary Of State For Scotland'
    topic='N/A'
  />
)} />

<Route exact path='/uk/department-for-science-innovation-and-technology' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Science Innovation And Technology'
    officialURL='https://www.gov.uk/government/organisations/department-for-science-innovation-and-technology'
    missionStatement='The Department for Science, Innovation and Technology drives innovation and science research in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Science%20Innovation%20And%20Technology'
    feedName='Department For Science Innovation And Technology'
    topic='Science & Tech'
  />
)} />

<Route exact path='/uk/hm-treasury' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='HM Treasury'
    officialURL='https://www.gov.uk/government/organisations/hm-treasury'
    missionStatement='HM Treasury is responsible for developing and executing the UK’s public finance and economic policy.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=HM%20Treasury'
    feedName='HM Treasury'
    topic='Economy'
  />
)} />

<Route exact path='/uk/department-for-work-and-pensions' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Work And Pensions'
    officialURL='https://www.gov.uk/government/organisations/department-for-work-and-pensions'
    missionStatement='The Department for Work and Pensions aims to help people achieve financial independence and provide support to those who are unable to work.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Work%20And%20Pensions'
    feedName='Department For Work And Pensions'
    topic='N/A'
  />
)} />

<Route exact path='/uk/cabinet-office' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Cabinet Office'
    officialURL='https://www.gov.uk/government/organisations/cabinet-office'
    missionStatement='The Cabinet Office supports the Prime Minister and ensures the effective running of government.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Cabinet%20Office'
    feedName='Cabinet Office'
    topic='N/A'
  />
)} />

<Route exact path='/uk/department-for-transport' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Transport'
    officialURL='https://www.gov.uk/government/organisations/department-for-transport'
    missionStatement='The Department for Transport is responsible for overseeing transport systems and infrastructure in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Transport'
    feedName='Department For Transport'
    topic='Infrastructure'
  />
)} />

<Route exact path='/uk/foreign-commonwealth-development-office' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Foreign Commonwealth Development Office'
    officialURL='https://www.gov.uk/government/organisations/foreign-commonwealth-and-development-office'
    missionStatement='The Foreign, Commonwealth & Development Office manages the UK’s international relations and development programs.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Foreign%20Commonwealth%20Development%20Office'
    feedName='Foreign Commonwealth Development Office'
    topic='N/A'
  />
)} />

<Route exact path='/uk/home-office' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Home Office'
    officialURL='https://www.gov.uk/government/organisations/home-office'
    missionStatement='The Home Office is responsible for security, law and order, and immigration in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Home%20Office'
    feedName='Home Office'
    topic='N/A'
  />
)} />

<Route exact path='/uk/department-for-education' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Education'
    officialURL='https://www.gov.uk/government/organisations/department-for-education'
    missionStatement='The Department for Education oversees education and children’s services in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Education'
    feedName='Department For Education'
    topic='N/A'
  />
)} />

<Route exact path='/uk/ministry-of-defence' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Ministry Of Defence'
    officialURL='https://www.gov.uk/government/organisations/ministry-of-defence'
    missionStatement='The Ministry of Defence oversees national security and the armed forces in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Defence'
    feedName='Ministry Of Defence'
    topic='Defense'
  />
)} />

<Route exact path='/uk/department-for-business-and-trade' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Business And Trade'
    officialURL='https://www.gov.uk/government/organisations/department-for-business-and-trade'
    missionStatement='The Department for Business and Trade promotes business growth and trade in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Business%20And%20Trade'
    feedName='Department For Business And Trade'
    topic='Economy'
  />
)} />

<Route exact path='/uk/office-of-the-secretary-of-state-for-wales' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Office Of The Secretary Of State For Wales'
    officialURL='https://www.gov.uk/government/organisations/office-of-the-secretary-of-state-for-wales'
    missionStatement='The Office of the Secretary of State for Wales represents Wales within the UK Government.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Wales'
    feedName='Office Of The Secretary Of State For Wales'
    topic='N/A'
  />
)} />

<Route exact path='/uk/the-office-of-the-leader-of-the-house-of-commons' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='The Office Of The Leader Of The House Of Commons'
    officialURL='https://www.gov.uk/government/organisations/the-office-of-the-leader-of-the-house-of-commons'
    missionStatement='The Office of the Leader of the House of Commons supports the parliamentary business and government operations.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=The%20Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Commons'
    feedName='The Office Of The Leader Of The House Of Commons'
    topic='N/A'
  />
)} />

<Route exact path='/uk/ministry-of-justice' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Ministry Of Justice'
    officialURL='https://www.gov.uk/government/organisations/ministry-of-justice'
    missionStatement='The Ministry of Justice is responsible for the justice system and legal services in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Justice'
    feedName='Ministry Of Justice'
    topic='Justice'
  />
)} />

<Route exact path='/uk/department-for-levelling-up-housing-and-communities' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Levelling Up Housing And Communities'
    officialURL='https://www.gov.uk/government/organisations/department-for-levelling-up-housing-and-communities'
    missionStatement='The Department for Levelling Up, Housing and Communities works to improve housing and community life in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Levelling%20Up%20Housing%20And%20Communities'
    feedName='Department For Levelling Up Housing And Communities'
    topic='Infrastructure'
  />
)} />

<Route exact path='/uk/northern-ireland-office' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Northern Ireland Office'
    officialURL='https://www.gov.uk/government/organisations/northern-ireland-office'
    missionStatement='The Northern Ireland Office represents Northern Ireland’s interests and manages the relationship between the UK and Northern Ireland.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Northern%20Ireland%20Office'
    feedName='Northern Ireland Office'
    topic='N/A'
  />
)} />

<Route exact path='/uk/department-of-health-and-social-care' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department Of Health And Social Care'
    officialURL='https://www.gov.uk/government/organisations/department-of-health-and-social-care'
    missionStatement='The Department of Health and Social Care oversees health and social care services in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20Of%20Health%20And%20Social%20Care'
    feedName='Department Of Health And Social Care'
    topic='Health'
  />
)} />

<Route exact path='/uk/uk-export-finance' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='UK Export Finance'
    officialURL='https://www.gov.uk/government/organisations/uk-export-finance'
    missionStatement='UK Export Finance provides support to UK businesses seeking to export goods and services.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=UK%20Export%20Finance'
    feedName='UK Export Finance'
    topic='Economy'
  />
)} />

<Route exact path='/uk/department-for-energy-security-and-net-zero' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Department For Energy Security And Net Zero'
    officialURL='https://www.gov.uk/government/organisations/department-for-energy-security-and-net-zero'
    missionStatement='The Department for Energy Security and Net Zero works to ensure energy security and address climate change in the UK.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Energy%20Security%20And%20Net%20Zero'
    feedName='Department For Energy Security And Net Zero'
    topic='Infrastructure'
  />
)} />

<Route exact path='/uk/office-of-the-leader-of-the-house-of-lords' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Office Of The Leader Of The House Of Lords'
    officialURL='https://www.gov.uk/government/organisations/office-of-the-leader-of-the-house-of-lords'
    missionStatement='The Office of the Leader of the House of Lords supports the work of the House of Lords in Parliament.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Lords'
    feedName='Office Of The Leader Of The House Of Lords'
    topic='N/A'
  />
)} />

<Route exact path='/uk/attorney-generals-office' render={(props) => (
  <UKAgencyTemplate
    {...props}
    imageName='/ukExecutive.png'
    agencyName='Attorney Generals Office'
    officialURL='https://www.gov.uk/government/organisations/attorney-generals-office'
    missionStatement='The Attorney General’s Office provides legal services and advice to the government and represents the UK in legal matters.'
    rssURL='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office'
    feedName='Attorney Generals Office'
    topic='N/A'
  />
)} />

        <Route exact path='/uk/england' component={England}/>
        <Route exact path='/uk/scotland' component={Scotland}/>
        <Route exact path='/uk/wales' component={Wales}/>
        <Route exact path='/uk/northernireland' component={NorthernIreland}/>

           {/*Department of Agriculture */}
           <Route exact path='/us/agricultural-marketing-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Agricultural Marketing Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Agricultural%20Marketing%20Service' 
    feedName='Agricultural Marketing Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/agricultural-research-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Agricultural Research Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Agricultural%20Research%20Service' 
    feedName='Agricultural Research Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/animal-plant-health' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Animal and Plant Health Inspection Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Animal%20and%20Plant%20Health%20Inspection%20Service' 
    feedName='Animal and Plant Health Inspection Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/commodity-credit' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Commodity Credit Corporation' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Commodity%20Credit%20Corporation' 
    feedName='Commodity Credit Corporation Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/cooperative-state-research' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Cooperative State Research, Education, and Extension Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Cooperative%20State%20Research%2C%20Education%2C%20and%20Extension%20Service' 
    feedName='Cooperative State Research, Education, and Extension Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/economic-analysis-staff' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Economic Analysis Staff' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Economic%20Analysis%20Staff' 
    feedName='Economic Analysis Staff Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/economic-research-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Economic Research Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Economic%20Research%20Service' 
    feedName='Economic Research Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/farm-service-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Farm Service Agency' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Farm%20Service%20Agency' 
    feedName='Farm Service Agency Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/federal-crop-insurance' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Federal Crop Insurance Corporation' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Federal%20Crop%20Insurance%20Corporation' 
    feedName='Federal Crop Insurance Corporation Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/food-consumer-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Food and Consumer Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Food%20and%20Consumer%20Service' 
    feedName='Food and Consumer Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/food-nutrition-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Food and Nutrition Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Food%20and%20Nutrition%20Service' 
    feedName='Food and Nutrition Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/food-safety-inspection' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Food Safety and Inspection Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Food%20Safety%20and%20Inspection%20Service' 
    feedName='Food Safety and Inspection Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/foreign-agricultural' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Foreign Agricultural Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Foreign%20Agricultural%20Service' 
    feedName='Foreign Agricultural Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/forest-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Forest Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Forest%20Service' 
    feedName='Forest Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/national-agricultural-library' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='National Agricultural Library' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=National%20Agricultural%20Library' 
    feedName='National Agricultural Library Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/national-agricultural-statistics' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='National Agricultural Statistics Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=National%20Agricultural%20Statistics%20Service' 
    feedName='National Agricultural Statistics Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/nifa' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='National Institute of Food and Agriculture' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=National%20Institute%20of%20Food%20and%20Agriculture' 
    feedName='National Institute of Food and Agriculture Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/natural-resources-conservation' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Natural Resources Conservation Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Natural%20Resources%20Conservation%20Service' 
    feedName='Natural Resources Conservation Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/operations-office' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Operations Office' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Operations%20Office' 
    feedName='Operations Office Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/partnerships-public-engagement' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Partnerships and Public Engagement Office' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Partnerships%20and%20Public%20Engagement%20Office' 
    feedName='Partnerships and Public Engagement Office Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/procurement-property-management' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Procurement and Property Management, Office of' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Procurement%20and%20Property%20Management%2C%20Office%20of' 
    feedName='Procurement and Property Management, Office of Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/risk-management-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Risk Management Agency' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Risk%20Management%20Agency' 
    feedName='Risk Management Agency Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/rural-business-cooperative' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Rural Business-Cooperative Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Rural%20Business-Cooperative%20Service' 
    feedName='Rural Business-Cooperative Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/rural-housing-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Rural Housing Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Rural%20Housing%20Service' 
    feedName='Rural Housing Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/rural-utilities-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Rural Utilities Service' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Rural%20Utilities%20Service' 
    feedName='Rural Utilities Service Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/rural-telephone-bank' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Rural Telephone Bank' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Rural%20Telephone%20Bank' 
    feedName='Rural Telephone Bank Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/transportation-office' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='Transportation Office' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=Transportation%20Office' 
    feedName='Transportation Office Documents' 
    topic='Agriculture' 
  />
)} />

<Route exact path='/us/us-codex-office' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfAgriculture.svg' 
    agencyName='U.S. Codex Office' 
    officialURL='' 
    missionStatement='' 
    rssURL='&table_name=environment&agency=U.S.%20Codex%20Office' 
    feedName='U.S. Codex Office Documents' 
    topic='Agriculture' 
  />
)} />



{/*Department of Housing */}
<Route exact path='/us/housing-oversight' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHousing.png' 
    agencyName='Federal Housing Enterprise Oversight Office' 
    officialURL='https://www.fhfa.gov/SupervisionRegulation/OfficeofFederalHousingEnterpriseOversight' 
    missionStatement='The Federal Housing Enterprise Oversight Office is responsible for ensuring the capital adequacy and financial safety and soundness of Fannie Mae and Freddie Mac.' 
    rssURL='&table_name=hud&agency=Federal%20Housing%20Enterprise%20Oversight%20Office' 
    feedName='Federal Housing Enterprise Oversight Office' 
    topic='Housing' 
  />
)} />

<Route exact path='/us/gnma' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHousing.png' 
    agencyName='Government National Mortgage Association' 
    officialURL='https://www.ginniemae.gov/' 
    missionStatement='The Government National Mortgage Association, or Ginnie Mae, ensures the availability of affordable housing by guaranteeing mortgage-backed securities issued by approved lenders.' 
    rssURL='&table_name=hud&agency=Government%20National%20Mortgage%20Association' 
    feedName='Government National Mortgage Association' 
    topic='Housing' 
  />
)} />

{/*Department of Education */}
<Route exact path='/us/education-assessment' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEducation.png' 
    agencyName='National Assessment Governing Board' 
    officialURL='https://www.nagb.gov/' 
    missionStatement='The National Assessment Governing Board sets policy for the National Assessment of Educational Progress (NAEP), commonly known as The Nation’s Report Card.' 
    rssURL='&table_name=education&agency=National%20Assessment%20Governing%20Board' 
    feedName='National Assessment Governing Board' 
    topic='Education' 
  />
)} />

 {/*Department of Defense */}

 <Route exact path='/us/army' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Department of the Army' 
    officialURL='https://www.army.mil/' 
    missionStatement='The Department of the Army is responsible for land-based military operations and is one of the three military departments reporting to the Department of Defense.' 
    rssURL='&table_name=defense&agency=Army%20Department' 
    feedName='Department of the Army Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/navy' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Department of the Navy' 
    officialURL='https://www.navy.mil/' 
    missionStatement='The Department of the Navy is responsible for naval and amphibious operations, and it is one of the three military departments within the Department of Defense.' 
    rssURL='&table_name=defense&agency=Navy%20Department' 
    feedName='Department of the Navy Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/airforce' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Department of the Air Force' 
    officialURL='https://www.af.mil/' 
    missionStatement='The Department of the Air Force is responsible for air and space operations, and it is one of the three military departments within the Department of Defense.' 
    rssURL='&table_name=defense&agency=Air%20Force%20Department' 
    feedName='Department of the Air Force Documents' 
    topic='Defense' 
  />
)} />
 <Route exact path='/us/defense-acquisition-regulations-system' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Acquisition Regulations System' 
    officialURL='http://www.acq.osd.mil/dpap/dars/index.html' 
    missionStatement='The Defense Acquisition Regulations System (DARS) develops and maintains acquisition rules and guidance to facilitate the acquisition workforce as they acquire the goods and services DoD requires to ensure Americas warfighters continued worldwide success.' 
    rssURL='&table_name=defense&agency=Defense%20Acquisition%20Regulations%20System' 
    feedName='Defense Acquisition Regulations System Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-contract-audit-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Contract Audit Agency' 
    officialURL='http://www.dcaa.mil/' 
    missionStatement='While serving the public interest as its primary customer, The DCAA performs all necessary contract audits for the Department of Defense and provides accounting and financial advisory services regarding contracts and subcontracts to all DoD Components responsible for procurement and contract administration.' 
    rssURL='&table_name=defense&agency=Defense%20Contract%20Audit%20Agency' 
    feedName='Defense Contract Audit Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-contract-management-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Contract Management Agency' 
    officialURL='http://www.dcma.mil/' 
    missionStatement='The DCMA provides contract administration services to the Department of Defense Acquisition Enterprise and its partners to ensure delivery of quality products and services to the warfighter.' 
    rssURL='&table_name=defense&agency=Defense%20Contract%20Management%20Agency' 
    feedName='Defense Contract Management Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-finance-accounting-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Finance and Accounting Service' 
    officialURL='http://www.dfas.mil/' 
    missionStatement='The Defense Finance and Accounting Service (DFAS) is responsible for providing finance and accounting services for the Department of Defense and other federal agencies.' 
    rssURL='&table_name=defense&agency=Defense%20Finance%20and%20Accounting%20Service' 
    feedName='Defense Finance and Accounting Service Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-information-systems-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Information Systems Agency' 
    officialURL='http://www.disa.mil/' 
    missionStatement='The Defense Information Systems Agency (DISA) provides, operates, and assures command and control and information-sharing capabilities in direct support of joint warfighters, national level leaders, and other mission and coalition partners across the full spectrum of military operations.' 
    rssURL='&table_name=defense&agency=Defense%20Information%20Systems%20Agency' 
    feedName='Defense Information Systems Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-intelligence-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Intelligence Agency' 
    officialURL='http://www.dia.mil/' 
    missionStatement='The Defense Intelligence Agency (DIA) provides military intelligence to warfighters, defense policymakers, and force planners in the Department of Defense and the Intelligence Community.' 
    rssURL='&table_name=defense&agency=Defense%20Intelligence%20Agency' 
    feedName='Defense Intelligence Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-legal-services-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Legal Services Agency' 
    officialURL='http://www.dod.mil/dodgc/' 
    missionStatement='The Defense Legal Services Agency (DLSA) provides legal services to the Office of the Secretary of Defense and other Department of Defense components.' 
    rssURL='&table_name=defense&agency=Defense%20Legal%20Services%20Agency' 
    feedName='Defense Legal Services Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-logistics-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Logistics Agency' 
    officialURL='http://www.dla.mil/' 
    missionStatement='The Defense Logistics Agency (DLA) provides the Army, Marine Corps, Navy, Air Force, other federal agencies, and partner nation armed forces with a full spectrum of logistics, acquisition, and technical services.' 
    rssURL='&table_name=defense&agency=Defense%20Logistics%20Agency' 
    feedName='Defense Logistics Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-security-cooperation-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Security Cooperation Agency' 
    officialURL='http://www.dsca.mil/' 
    missionStatement='The Defense Security Cooperation Agency (DSCA) provides financial and technical assistance, transfer of defense matériel, training, and services to allies, and promotes military-to-military contacts.' 
    rssURL='&table_name=defense&agency=Defense%20Security%20Cooperation%20Agency' 
    feedName='Defense Security Cooperation Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-technical-information-center' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Technical Information Center' 
    officialURL='http://www.dtic.mil/' 
    missionStatement='The Defense Technical Information Center (DTIC) provides essential scientific and technical information to the Department of Defense, supporting the research and engineering communities.' 
    rssURL='&table_name=defense&agency=Defense%20Technical%20Information%20Center' 
    feedName='Defense Technical Information Center Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/defense-threat-reduction-agency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='Defense Threat Reduction Agency' 
    officialURL='http://www.dtra.mil/' 
    missionStatement='The Defense Threat Reduction Agency (DTRA) safeguards the United States and its allies from global weapons of mass destruction (WMD) threats by integrating, synchronizing, and providing expertise, technologies, and capabilities.' 
    rssURL='&table_name=defense&agency=Defense%20Threat%20Reduction%20Agency' 
    feedName='Defense Threat Reduction Agency Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/national-guard-bureau' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='National Guard Bureau' 
    officialURL='http://www.nationalguard.mil/' 
    missionStatement='The National Guard Bureau provides federal, state, and territorial National Guard organizations with support, guidance, and oversight to ensure the highest levels of readiness and capability.' 
    rssURL='&table_name=defense&agency=National%20Guard%20Bureau' 
    feedName='National Guard Bureau Documents' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/engineerscorps' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfDefense.png' 
    agencyName='U.S. Army Corps of Engineers' 
    officialURL='https://www.usace.army.mil/' 
    missionStatement='The U.S. Army Corps of Engineers (USACE) delivers vital public and military engineering services; partnering in peace and war to strengthen our Nation’s security, energize the economy and reduce risks from disasters.' 
    rssURL='&table_name=defense&agency=U.S.%20Army%20Corps%20of%20Engineers' 
    feedName='U.S. Army Corps of Engineers Documents' 
    topic='Defense' 
  />
)} />

         {/*Department of Health */}
 <Route exact path='/us/healthcare' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfHealth.png' 
            agencyName='Agency for Healthcare Research and Quality' 
            officialURL='http://www.ahrq.gov/about/ahrqfact.htm' 
            missionStatement='AHRQ is the health services research arm of the U.S. Department of Health and Human Services (HHS).' 
            rssURL='&table_name=health_and_human_services&agency=Agency%20for%20Healthcare%20Research%20and%20Quality' 
            feedName='Agency for Healthcare Research and Quality' 
            topic='Health' 
          />
        )} />
        <Route exact path='/us/toxic-substances' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Agency for Toxic Substances and Disease Registry' 
    officialURL='https://www.atsdr.cdc.gov/' 
    missionStatement='ATSDR protects communities from harmful health effects related to exposure to natural and man-made hazardous substances.' 
    rssURL='&table_name=health_and_human_services&agency=Agency%20for%20Toxic%20Substances%20and%20Disease%20Registry' 
    feedName='Agency for Toxic Substances and Disease Registry' 
    topic='Health' 
  />
)} />

<Route exact path='/us/aging-administration' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Aging Administration' 
    officialURL='https://acl.gov/' 
    missionStatement='The Administration for Community Living (ACL) provides funding and support to programs that serve older adults and people with disabilities.' 
    rssURL='&table_name=health_and_human_services&agency=Aging%20Administration' 
    feedName='Aging Administration' 
    topic='Health' 
  />
)} />

<Route exact path='/us/cdc' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Centers for Disease Control and Prevention' 
    officialURL='https://www.cdc.gov/' 
    missionStatement='CDC works 24/7 to protect America from health, safety, and security threats, both foreign and domestic.' 
    rssURL='&table_name=health_and_human_services&agency=Centers%20for%20Disease%20Control%20and%20Prevention' 
    feedName='Centers for Disease Control and Prevention' 
    topic='Health' 
  />
)} />

<Route exact path='/us/medicare-medicaid' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Centers for Medicare & Medicaid Services' 
    officialURL='https://www.cms.gov/' 
    missionStatement='CMS administers the nation’s major healthcare programs including Medicare, Medicaid, and the Children’s Health Insurance Program (CHIP).' 
    rssURL='&table_name=health_and_human_services&agency=Centers%20for%20Medicare%20&%20Medicaid%20Services' 
    feedName='Centers for Medicare & Medicaid Services' 
    topic='Health' 
  />
)} />

<Route exact path='/us/child-support' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Child Support Enforcement Office' 
    officialURL='https://www.acf.hhs.gov/css' 
    missionStatement='OCSE oversees the national child support program, which touches the lives of about one in five children.' 
    rssURL='&table_name=health_and_human_services&agency=Child%20Support%20Enforcement%20Office' 
    feedName='Child Support Enforcement Office' 
    topic='Health' 
  />
)} />

<Route exact path='/us/children-families' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Children and Families Administration' 
    officialURL='https://www.acf.hhs.gov/' 
    missionStatement='ACF promotes the economic and social well-being of families, children, individuals, and communities.' 
    rssURL='&table_name=health_and_human_services&agency=Children%20and%20Families%20Administration' 
    feedName='Children and Families Administration' 
    topic='Health' 
  />
)} />

<Route exact path='/us/community-living' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Community Living Administration' 
    officialURL='https://acl.gov/' 
    missionStatement='The Administration for Community Living (ACL) helps older adults and people with disabilities live independently and participate fully in their communities.' 
    rssURL='&table_name=health_and_human_services&agency=Community%20Living%20Administration' 
    feedName='Community Living Administration' 
    topic='Health' 
  />
)} />

<Route exact path='/us/fda' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Food and Drug Administration' 
    officialURL='https://www.fda.gov/' 
    missionStatement='FDA protects the public health by ensuring the safety, efficacy, and security of human and veterinary drugs, biological products, and medical devices.' 
    rssURL='&table_name=health_and_human_services&agency=Food%20and%20Drug%20Administration' 
    feedName='Food and Drug Administration' 
    topic='Health' 
  />
)} />

<Route exact path='/us/health-care-finance' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Health Care Finance Administration' 
    officialURL='https://www.cms.gov/' 
    missionStatement='HCFA oversees Medicare and Medicaid programs to provide healthcare to eligible Americans.' 
    rssURL='&table_name=health_and_human_services&agency=Health%20Care%20Finance%20Administration' 
    feedName='Health Care Finance Administration' 
    topic='Health' 
  />
)} />

<Route exact path='/us/health-resources' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Health Resources and Services Administration' 
    officialURL='https://www.hrsa.gov/' 
    missionStatement='HRSA provides healthcare to people who are geographically isolated and economically or medically vulnerable.' 
    rssURL='&table_name=health_and_human_services&agency=Health%20Resources%20and%20Services%20Administration' 
    feedName='Health Resources and Services Administration' 
    topic='Health' 
  />
)} />

<Route exact path='/us/indian-health' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Indian Health Service' 
    officialURL='https://www.ihs.gov/' 
    missionStatement='IHS provides healthcare services to American Indians and Alaska Natives.' 
    rssURL='&table_name=health_and_human_services&agency=Indian%20Health%20Service' 
    feedName='Indian Health Service' 
    topic='Health' 
  />
)} />

<Route exact path='/us/nih' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='National Institutes of Health' 
    officialURL='https://www.nih.gov/' 
    missionStatement='NIH is the primary agency of the U.S. government responsible for biomedical and public health research.' 
    rssURL='&table_name=health_and_human_services&agency=National%20Institutes%20of%20Health' 
    feedName='National Institutes of Health' 
    topic='Health' 
  />
)} />

<Route exact path='/us/national-library-medicine' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='National Library of Medicine' 
    officialURL='https://www.nlm.nih.gov/' 
    missionStatement='NLM is the world’s largest biomedical library and a leader in research in computational health sciences.' 
    rssURL='&table_name=health_and_human_services&agency=National%20Library%20of%20Medicine' 
    feedName='National Library of Medicine' 
    topic='Health' 
  />
)} />

<Route exact path='/us/program-support-center' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Program Support Center' 
    officialURL='https://psc.gov/' 
    missionStatement='PSC provides a range of shared services to support the administrative needs of HHS and other federal agencies.' 
    rssURL='&table_name=health_and_human_services&agency=Program%20Support%20Center' 
    feedName='Program Support Center' 
    topic='Health' 
  />
)} />

<Route exact path='/us/public-health-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Public Health Service' 
    officialURL='https://www.usphs.gov/' 
    missionStatement='PHS is responsible for protecting, promoting, and advancing the health and safety of the nation.' 
    rssURL='&table_name=health_and_human_services&agency=Public%20Health%20Service' 
    feedName='Public Health Service' 
    topic='Health' 
  />
)} />

<Route exact path='/us/refugee-resettlement' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Refugee Resettlement Office' 
    officialURL='https://www.acf.hhs.gov/orr' 
    missionStatement='ORR provides new populations with the opportunity to maximize their potential in the United States.' 
    rssURL='&table_name=health_and_human_services&agency=Refugee%20Resettlement%20Office' 
    feedName='Refugee Resettlement Office' 
    topic='Health' 
  />
)} />

<Route exact path='/us/strategic-preparedness' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Strategic Preparedness and Response Administration' 
    officialURL='https://www.hhs.gov/about/agencies/aspr/index.html' 
    missionStatement='ASPR leads the nation’s medical and public health preparedness for, response to, and recovery from disasters and public health emergencies.' 
    rssURL='&table_name=health_and_human_services&agency=Strategic%20Preparedness%20and%20Response%20Administration' 
    feedName='Strategic Preparedness and Response Administration' 
    topic='Health' 
  />
)} />

<Route exact path='/us/substance-abuse' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHealth.png' 
    agencyName='Substance Abuse and Mental Health Services Administration' 
    officialURL='https://www.samhsa.gov/' 
    missionStatement='SAMHSA leads public health efforts to advance the behavioral health of the nation.' 
    rssURL='&table_name=health_and_human_services&agency=Substance%20Abuse%20and%20Mental%20Health%20Services%20Administration' 
    feedName='Substance Abuse and Mental Health Services Administration' 
    topic='Health' 
  />
)} />

      {/*Department of Commerce*/}
      <Route exact path='/us/census-bureau' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfCommerce.png' 
            agencyName='Census Bureau' 
            officialURL='http://www.census.gov/' 
            missionStatement='The Census Bureau is responsible for the the decennial censuses of population and housing; the quinquennial censuses of State and local governments, manufacturers, mineral industries, distributive trades, construction industries, and transportation.' 
            rssURL='&table_name=commerce&agency=Census%20Bureau' 
            feedName='Census Bureau' 
            topic='Commerce' 
          />
        )} />

<Route exact path='/us/economic-analysis-bureau' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Economic Analysis Bureau' 
    officialURL='https://www.bea.gov/' 
    missionStatement='BEA produces economic accounts statistics that enable government and business decision-makers, researchers, and the American public to follow and understand the performance of the Nation’s economy.' 
    rssURL='&table_name=commerce&agency=Economic%20Analysis%20Bureau' 
    feedName='Economic Analysis Bureau' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/economic-development-administration' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Economic Development Administration' 
    officialURL='https://www.eda.gov/' 
    missionStatement='EDA’s mission is to lead the federal economic development agenda by promoting innovation and competitiveness, preparing American regions for growth and success in the worldwide economy.' 
    rssURL='&table_name=commerce&agency=Economic%20Development%20Administration' 
    feedName='Economic Development Administration' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/economics-statistics-administration' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Economics and Statistics Administration' 
    officialURL='https://www.esa.gov/' 
    missionStatement='ESA provides timely economic analysis, disseminates national economic indicators, and oversees the U.S. Census Bureau and the Bureau of Economic Analysis.' 
    rssURL='&table_name=commerce&agency=Economics%20and%20Statistics%20Administration' 
    feedName='Economics and Statistics Administration' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/export-administration-bureau' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Export Administration Bureau' 
    officialURL='https://www.bis.doc.gov/' 
    missionStatement='The Bureau of Industry and Security (BIS) advances U.S. national security, foreign policy, and economic objectives by ensuring an effective export control and treaty compliance system and promoting continued U.S. strategic technology leadership.' 
    rssURL='&table_name=commerce&agency=Export%20Administration%20Bureau' 
    feedName='Export Administration Bureau' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/foreign-trade-zones-board' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Foreign-Trade Zones Board' 
    officialURL='https://enforcement.trade.gov/ftzpage/' 
    missionStatement='The FTZ Board provides communities with the authority to designate areas where companies can use special procedures to encourage U.S. activity and value added — in competition with foreign alternatives — by reducing, eliminating, or delaying duties on imports.' 
    rssURL='&table_name=commerce&agency=Foreign-Trade%20Zones%20Board' 
    feedName='Foreign-Trade Zones Board' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/industry-security-bureau' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Industry and Security Bureau' 
    officialURL='https://www.bis.doc.gov/' 
    missionStatement='BIS advances U.S. national security, foreign policy, and economic objectives by ensuring an effective export control and treaty compliance system and promoting continued U.S. strategic technology leadership.' 
    rssURL='&table_name=commerce&agency=Industry%20and%20Security%20Bureau' 
    feedName='Industry and Security Bureau' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/international-trade-administration' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='International Trade Administration' 
    officialURL='https://www.trade.gov/' 
    missionStatement='ITA strengthens the competitiveness of U.S. industry, promotes trade and investment, and ensures fair trade through the rigorous enforcement of our trade laws and agreements.' 
    rssURL='&table_name=commerce&agency=International%20Trade%20Administration' 
    feedName='International Trade Administration' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/minority-business-development' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Minority Business Development Agency' 
    officialURL='https://www.mbda.gov/' 
    missionStatement='MBDA promotes the growth of minority-owned businesses through the mobilization and advancement of public and private sector programs, policy, and research.' 
    rssURL='&table_name=commerce&agency=Minority%20Business%20Development%20Agency' 
    feedName='Minority Business Development Agency' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/nist' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='National Institute of Standards and Technology' 
    officialURL='https://www.nist.gov/' 
    missionStatement='NIST promotes U.S. innovation and industrial competitiveness by advancing measurement science, standards, and technology.' 
    rssURL='&table_name=commerce&agency=National%20Institute%20of%20Standards%20and%20Technology' 
    feedName='National Institute of Standards and Technology' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/noaa' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='National Oceanic and Atmospheric Administration' 
    officialURL='https://www.noaa.gov/' 
    missionStatement='NOAA enriches life through science. It provides actionable information about the oceans, atmosphere, and marine resources.' 
    rssURL='&table_name=commerce&agency=National%20Oceanic%20and%20Atmospheric%20Administration' 
    feedName='National Oceanic and Atmospheric Administration' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/ntis' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='National Technical Information Service' 
    officialURL='https://www.ntis.gov/' 
    missionStatement='NTIS helps federal agencies make better decisions about data, with innovative approaches to data, including data science, data interoperability, data analytics, and data dissemination.' 
    rssURL='&table_name=commerce&agency=National%20Technical%20Information%20Service' 
    feedName='National Technical Information Service' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/ntia' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='National Telecommunications and Information Administration' 
    officialURL='https://www.ntia.doc.gov/' 
    missionStatement='NTIA is the Executive Branch agency that is principally responsible for advising the President on telecommunications and information policy issues.' 
    rssURL='&table_name=commerce&agency=National%20Telecommunications%20and%20Information%20Administration' 
    feedName='National Telecommunications and Information Administration' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/patent-trademark' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Patent and Trademark Office' 
    officialURL='https://www.uspto.gov/' 
    missionStatement='The USPTO is responsible for granting U.S. patents and registering trademarks. It advances the nation’s progress in innovation and industrial productivity.' 
    rssURL='&table_name=commerce&agency=Patent%20and%20Trademark%20Office' 
    feedName='Patent and Trademark Office' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/technology-administration' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Technology Administration' 
    officialURL='https://www.nist.gov/' 
    missionStatement='The Technology Administration promotes technological innovation and advancement in the United States.' 
    rssURL='&table_name=commerce&agency=Technology%20Administration' 
    feedName='Technology Administration' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/travel-tourism-administration' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Travel and Tourism Administration' 
    officialURL='https://www.trade.gov/industry-information/us-travel-and-tourism-advisory-board' 
    missionStatement='The Travel and Tourism Administration promotes the United States as a premier travel destination and provides policy recommendations on the travel industry.' 
    rssURL='&table_name=commerce&agency=Travel%20and%20Tourism%20Administration' 
    feedName='Travel and Tourism Administration' 
    topic='Commerce' 
  />
)} />

<Route exact path='/us/under-secretary-economic-affairs' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfCommerce.png' 
    agencyName='Under-Secretary for Economic Affairs' 
    officialURL='https://www.commerce.gov/' 
    missionStatement='The Under-Secretary for Economic Affairs advises on economic policy and provides data analysis and insights to foster economic growth and opportunity.' 
    rssURL='&table_name=commerce&agency=Under-Secretary%20for%20Economic%20Affairs' 
    feedName='Under-Secretary for Economic Affairs' 
    topic='Commerce' 
  />
)} />


 {/*Department of Energy*/}
 <Route exact path='/us/alaskapower' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfEnergy.png' 
            agencyName='Alaska Power Administration' 
            officialURL='' 
            missionStatement='' 
            rssURL='&table_name=energy&agency=Alaska%20Power%20Administration' 
            feedName='Alaska Power Administration' 
            topic='Infrastructure' 
          />
        )} />

<Route exact path='/us/bonneville-power' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Bonneville Power Administration' 
    officialURL='https://www.bpa.gov/' 
    missionStatement='BPA markets wholesale electrical power from federal hydroelectric projects in the Columbia River Basin.' 
    rssURL='&table_name=energy&agency=Bonneville%20Power%20Administration' 
    feedName='Bonneville Power Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/energy-efficiency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Energy Efficiency and Renewable Energy Office' 
    officialURL='https://www.energy.gov/eere/office-energy-efficiency-renewable-energy' 
    missionStatement='EERE works to create and sustain American leadership in the transition to a global clean energy economy.' 
    rssURL='&table_name=energy&agency=Energy%20Efficiency%20and%20Renewable%20Energy%20Office' 
    feedName='Energy Efficiency and Renewable Energy Office' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/energy-information' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Energy Information Administration' 
    officialURL='https://www.eia.gov/' 
    missionStatement='EIA collects, analyzes, and disseminates independent and impartial energy information.' 
    rssURL='&table_name=energy&agency=Energy%20Information%20Administration' 
    feedName='Energy Information Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/energy-research' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Energy Research Office' 
    officialURL='https://www.energy.gov/science/office-science' 
    missionStatement='The Office of Science is the lead federal agency supporting fundamental scientific research for energy and the nation’s largest supporter of basic research in the physical sciences.' 
    rssURL='&table_name=energy&agency=Energy%20Research%20Office' 
    feedName='Energy Research Office' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/environment-office' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Environment Office, Energy Department' 
    officialURL='https://www.energy.gov/environment' 
    missionStatement='The Office of Environment, Health, Safety, and Security provides corporate leadership and strategic approaches for protecting the environment.' 
    rssURL='&table_name=energy&agency=Environment%20Office%2C%20Energy%20Department' 
    feedName='Environment Office, Energy Department' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/ferc' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Federal Energy Regulatory Commission' 
    officialURL='https://www.ferc.gov/' 
    missionStatement='FERC regulates the interstate transmission of electricity, natural gas, and oil.' 
    rssURL='&table_name=energy&agency=Federal%20Energy%20Regulatory%20Commission' 
    feedName='Federal Energy Regulatory Commission' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/nnsa' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='National Nuclear Security Administration' 
    officialURL='https://www.energy.gov/nnsa/national-nuclear-security-administration' 
    missionStatement='NNSA maintains and enhances the safety, security, and effectiveness of the U.S. nuclear weapons stockpile.' 
    rssURL='&table_name=energy&agency=National%20Nuclear%20Security%20Administration' 
    feedName='National Nuclear Security Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/southeastern-power' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Southeastern Power Administration' 
    officialURL='https://www.energy.gov/sepa/southeastern-power-administration' 
    missionStatement='SEPA markets federal hydroelectric power to public bodies and cooperatives.' 
    rssURL='&table_name=energy&agency=Southeastern%20Power%20Administration' 
    feedName='Southeastern Power Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/southwestern-power' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Southwestern Power Administration' 
    officialURL='https://www.energy.gov/swpa/southwestern-power-administration' 
    missionStatement='SWPA markets and delivers hydroelectric power from projects operated by the U.S. Army Corps of Engineers.' 
    rssURL='&table_name=energy&agency=Southwestern%20Power%20Administration' 
    feedName='Southwestern Power Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/western-power' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfEnergy.png' 
    agencyName='Western Area Power Administration' 
    officialURL='https://www.wapa.gov/' 
    missionStatement='WAPA markets and transmits wholesale electricity from multi-use water projects.' 
    rssURL='&table_name=energy&agency=Western%20Area%20Power%20Administration' 
    feedName='Western Area Power Administration' 
    topic='Infrastructure' 
  />
)} />

{/*Department of Transportation*/}
<Route exact path='/us/commercialspace' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfTransportation.png' 
            agencyName='Commercial Space Transportation Office' 
            officialURL='http://www.faa.gov/about/office_org/headquarters_offices/ast/' 
            missionStatement='The Commercial Space Transportation Office regulates and encourages the U.S. commercial space transportation industry. It licenses the private sector launching of space payloads on expendable launch vehicles and commercial space launch facilities.' 
            rssURL='&table_name=transportation&agency=Commercial%20Space%20Transportation%20Office' 
            feedName='Commercial Space Transportation Office' 
            topic='Infrastructure' 
          />
        )} />

<Route exact path='/us/faa' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Federal Aviation Administration' 
    officialURL='https://www.faa.gov/' 
    missionStatement='FAA provides the safest, most efficient aerospace system in the world.' 
    rssURL='&table_name=transportation&agency=Federal%20Aviation%20Administration' 
    feedName='Federal Aviation Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/fha' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Federal Highway Administration' 
    officialURL='https://www.fhwa.dot.gov/' 
    missionStatement='FHWA supports State and local governments in the design, construction, and maintenance of the Nation’s highway system and various federally and tribal owned lands.' 
    rssURL='&table_name=transportation&agency=Federal%20Highway%20Administration' 
    feedName='Federal Highway Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/fmcsa' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Federal Motor Carrier Safety Administration' 
    officialURL='https://www.fmcsa.dot.gov/' 
    missionStatement='FMCSA is dedicated to improving the safety of commercial motor vehicles and saving lives.' 
    rssURL='&table_name=transportation&agency=Federal%20Motor%20Carrier%20Safety%20Administration' 
    feedName='Federal Motor Carrier Safety Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/fra' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Federal Railroad Administration' 
    officialURL='https://railroads.dot.gov/' 
    missionStatement='FRA’s mission is to enable the safe, reliable, and efficient movement of people and goods.' 
    rssURL='&table_name=transportation&agency=Federal%20Railroad%20Administration' 
    feedName='Federal Railroad Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/fta' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Federal Transit Administration' 
    officialURL='https://www.transit.dot.gov/' 
    missionStatement='FTA provides financial and technical assistance to local public transit systems.' 
    rssURL='&table_name=transportation&agency=Federal%20Transit%20Administration' 
    feedName='Federal Transit Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/great-lakes' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Great Lakes St. Lawrence Seaway Development Corporation' 
    officialURL='https://www.seaway.dot.gov/' 
    missionStatement='The GLS works to enhance the Great Lakes St. Lawrence Seaway System for commercial users, shippers, and communities.' 
    rssURL='&table_name=transportation&agency=Great%20Lakes%20St.%20Lawrence%20Seaway%20Development%20Corporation' 
    feedName='Great Lakes St. Lawrence Seaway Development Corporation' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/maritime' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Maritime Administration' 
    officialURL='https://www.maritime.dot.gov/' 
    missionStatement='MARAD promotes the use of waterborne transportation and its seamless integration with other segments of the transportation system.' 
    rssURL='&table_name=transportation&agency=Maritime%20Administration' 
    feedName='Maritime Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/highway-safety' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='National Highway Traffic Safety Administration' 
    officialURL='https://www.nhtsa.gov/' 
    missionStatement='NHTSA saves lives, prevents injuries, and reduces traffic-related health care and other economic costs.' 
    rssURL='&table_name=transportation&agency=National%20Highway%20Traffic%20Safety%20Administration' 
    feedName='National Highway Traffic Safety Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/motor-carrier-safety' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Office of Motor Carrier Safety' 
    officialURL='https://www.fmcsa.dot.gov/' 
    missionStatement='The Office of Motor Carrier Safety is dedicated to improving the safety of commercial motor vehicles and saving lives.' 
    rssURL='&table_name=transportation&agency=Office%20of%20Motor%20Carrier%20Safety' 
    feedName='Office of Motor Carrier Safety' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/pipeline-hazardous-materials' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Pipeline and Hazardous Materials Safety Administration' 
    officialURL='https://www.phmsa.dot.gov/' 
    missionStatement='PHMSA protects people and the environment by advancing the safe transportation of energy and other hazardous materials that are essential to our daily lives.' 
    rssURL='&table_name=transportation&agency=Pipeline%20and%20Hazardous%20Materials%20Safety%20Administration' 
    feedName='Pipeline and Hazardous Materials Safety Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/innovative-technology' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Research and Innovative Technology Administration' 
    officialURL='https://www.transportation.gov/research-and-technology' 
    missionStatement='RITA coordinates the Department’s research and development programs and fosters the use of innovative technologies.' 
    rssURL='&table_name=transportation&agency=Research%20and%20Innovative%20Technology%20Administration' 
    feedName='Research and Innovative Technology Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/research-special-programs' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Research and Special Programs Administration' 
    officialURL='https://www.phmsa.dot.gov/research-and-development/rspa-innovation-safety' 
    missionStatement='RSPA ensures the safe, secure, and efficient transportation of hazardous materials essential to our daily lives.' 
    rssURL='&table_name=transportation&agency=Research%20and%20Special%20Programs%20Administration' 
    feedName='Research and Special Programs Administration' 
    topic='Infrastructure' 
  />
)} />

<Route exact path='/us/transportation-statistics' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTransportation.png' 
    agencyName='Transportation Statistics Bureau' 
    officialURL='https://www.bts.gov/' 
    missionStatement='BTS compiles, analyzes, and makes accessible information on the nation’s transportation systems.' 
    rssURL='&table_name=transportation&agency=Transportation%20Statistics%20Bureau' 
    feedName='Transportation Statistics Bureau' 
    topic='Infrastructure' 
  />
)} />


{/*Department of Treasury */}
<Route exact path='/us/alcohol-tobacco' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfTreasury.png' 
            agencyName='Alcohol and Tobacco Tax and Trade Bureau' 
            officialURL='http://www.ttb.gov' 
            missionStatement='The mission of TTB is to collect alcohol, tobacco, firearms, and ammunition excise taxes that are rightfully due; to protect the consumer of alcohol beverages through compliance programs that are based upon education and enforcement of the industry to ensure an effectively regulated marketplace; and to assist industry members to understand and comply with Federal tax, product, and marketing requirements associated with the commodities we regulate.' 
            rssURL='&table_name=treasury&agency=Alcohol%20and%20Tobacco%20Tax%20and%20Trade%20Bureau' 
            feedName='Alcohol and Tobacco Tax and Trade Bureau' 
            topic='Economy' 
          />
        )} />

<Route exact path='/us/fiscal-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Bureau of the Fiscal Service' 
    officialURL='https://www.fiscal.treasury.gov/' 
    missionStatement='The Bureau of the Fiscal Service promotes the financial integrity and operational efficiency of the federal government through exceptional accounting, financing, collections, payments, and shared services.' 
    rssURL='&table_name=treasury&agency=Bureau%20of%20the%20Fiscal%20Service' 
    feedName='Bureau of the Fiscal Service' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/cdfi-fund' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Community Development Financial Institutions Fund' 
    officialURL='https://www.cdfifund.gov/' 
    missionStatement='The CDFI Fund works to expand economic opportunity for underserved people and communities by supporting the growth and capacity of a national network of community development lenders, investors, and financial service providers.' 
    rssURL='&table_name=treasury&agency=Community%20Development%20Financial%20Institutions%20Fund' 
    feedName='Community Development Financial Institutions Fund' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/comptroller-currency' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Comptroller of the Currency' 
    officialURL='https://www.occ.treas.gov/' 
    missionStatement='The Office of the Comptroller of the Currency ensures that national banks and federal savings associations operate in a safe and sound manner and comply with all applicable laws and regulations.' 
    rssURL='&table_name=treasury&agency=Comptroller%20of%20the%20Currency' 
    feedName='Comptroller of the Currency' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/customs-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Customs Service' 
    officialURL='https://www.cbp.gov/' 
    missionStatement='The Customs Service facilitates lawful international trade and travel while enforcing U.S. laws and regulations.' 
    rssURL='&table_name=treasury&agency=Customs%20Service' 
    feedName='Customs Service' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/engraving-printing' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Engraving and Printing Bureau' 
    officialURL='https://www.moneyfactory.gov/' 
    missionStatement='The Bureau of Engraving and Printing designs and produces U.S. currency notes and other security documents.' 
    rssURL='&table_name=treasury&agency=Engraving%20and%20Printing%20Bureau' 
    feedName='Engraving and Printing Bureau' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/fincen' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Financial Crimes Enforcement Network' 
    officialURL='https://www.fincen.gov/' 
    missionStatement='FinCEN safeguards the financial system from illicit use, combats money laundering, and promotes national security through the collection, analysis, and dissemination of financial intelligence and strategic use of financial authorities.' 
    rssURL='&table_name=treasury&agency=Financial%20Crimes%20Enforcement%20Network' 
    feedName='Financial Crimes Enforcement Network' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/financial-research' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Financial Research Office' 
    officialURL='https://www.financialresearch.gov/' 
    missionStatement='The Office of Financial Research delivers high-quality financial data, standards, and analysis to promote financial stability.' 
    rssURL='&table_name=treasury&agency=Financial%20Research%20Office' 
    feedName='Financial Research Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/fiscal-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Fiscal Service' 
    officialURL='https://www.fiscal.treasury.gov/' 
    missionStatement='The Bureau of the Fiscal Service promotes the financial integrity and operational efficiency of the federal government through exceptional accounting, financing, collections, payments, and shared services.' 
    rssURL='&table_name=treasury&agency=Fiscal%20Service' 
    feedName='Fiscal Service' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/foreign-assets-control' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Foreign Assets Control Office' 
    officialURL='https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information' 
    missionStatement='OFAC administers and enforces economic and trade sanctions based on U.S. foreign policy and national security goals.' 
    rssURL='&table_name=treasury&agency=Foreign%20Assets%20Control%20Office' 
    feedName='Foreign Assets Control Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/irs' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Internal Revenue Service' 
    officialURL='https://www.irs.gov/' 
    missionStatement='The IRS administers the nation’s tax laws and collects the revenue that funds the federal government.' 
    rssURL='&table_name=treasury&agency=Internal%20Revenue%20Service' 
    feedName='Internal Revenue Service' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/international-investment' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='International Investment Office' 
    officialURL='https://home.treasury.gov/policy-issues/international/international-economic-policy' 
    missionStatement='The International Investment Office promotes policies to attract foreign direct investment to the United States and supports international economic growth.' 
    rssURL='&table_name=treasury&agency=International%20Investment%20Office' 
    feedName='International Investment Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/investment-security' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Investment Security Office' 
    officialURL='https://home.treasury.gov/policy-issues/international/investment-security' 
    missionStatement='The Investment Security Office ensures that foreign investments in the United States do not harm national security.' 
    rssURL='&table_name=treasury&agency=Investment%20Security%20Office' 
    feedName='Investment Security Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/monetary-offices' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Monetary Offices' 
    officialURL='https://home.treasury.gov/policy-issues/international/monetary-affairs' 
    missionStatement='Monetary Offices provide economic and policy analysis related to the U.S. and international monetary systems.' 
    rssURL='&table_name=treasury&agency=Monetary%20Offices' 
    feedName='Monetary Offices' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/public-debt' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Public Debt Bureau' 
    officialURL='https://www.treasurydirect.gov/' 
    missionStatement='The Public Debt Bureau borrows the money needed to operate the federal government and accounts for the resulting debt.' 
    rssURL='&table_name=treasury&agency=Public%20Debt%20Bureau' 
    feedName='Public Debt Bureau' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/thrift-supervision' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='Thrift Supervision Office' 
    officialURL='https://www.occ.treas.gov/about/who-we-are/history/ots.html' 
    missionStatement='The Office of Thrift Supervision was responsible for overseeing federal savings and loan associations.' 
    rssURL='&table_name=treasury&agency=Thrift%20Supervision%20Office' 
    feedName='Thrift Supervision Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/us-mint' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfTreasury.png' 
    agencyName='United States Mint' 
    officialURL='https://www.usmint.gov/' 
    missionStatement='The United States Mint produces coinage for the United States to conduct its trade and commerce.' 
    rssURL='&table_name=treasury&agency=United%20States%20Mint' 
    feedName='United States Mint' 
    topic='Economy' 
  />
)} />

{/*Department of Labor */}
<Route exact path='/us/benefits-review-board' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfLabor.png' 
            agencyName='Benefits Review Board' 
            officialURL='https://www.dol.gov/brb/welcome.html' 
            missionStatement='The Department of Labors Benefits Review Board was created in 1972, by Congress, to review and issue decisions on appeals of workers compensation claims arising under the Longshore and Harbor Workers Compensation Act and the Black Lung Benefits amendments to the Federal Coal Mine Health and Safety Act of 1969.' 
            rssURL='&table_name=labor&agency=Benefits%20Review%20Board' 
            feedName='Benefits Review Board' 
            topic='Economy' 
          />
        )} />

<Route exact path='/us/disability-employment-policy' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Disability Employment Policy Office' 
    officialURL='https://www.dol.gov/odep/' 
    missionStatement='ODEP works to develop and influence policies and practices that increase the number and quality of employment opportunities for people with disabilities.' 
    rssURL='&table_name=labor&agency=Disability%20Employment%20Policy%20Office' 
    feedName='Disability Employment Policy Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/employee-benefits-security' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Employee Benefits Security Administration' 
    officialURL='https://www.dol.gov/agencies/ebsa' 
    missionStatement='EBSA is committed to ensuring the security of the retirement, health, and other workplace-related benefits of America’s workers and their families.' 
    rssURL='&table_name=labor&agency=Employee%20Benefits%20Security%20Administration' 
    feedName='Employee Benefits Security Administration' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/employees-compensation-appeals' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Employees Compensation Appeals Board' 
    officialURL='https://www.dol.gov/agencies/ecab' 
    missionStatement='ECAB is an independent adjudicatory body that hears and decides cases of federal employees appealing adverse decisions regarding workers’ compensation claims.' 
    rssURL='&table_name=labor&agency=Employees%20Compensation%20Appeals%20Board' 
    feedName='Employees Compensation Appeals Board' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/employment-training' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Employment and Training Administration' 
    officialURL='https://www.dol.gov/agencies/eta' 
    missionStatement='ETA provides job training, employment, labor market information, and income maintenance services primarily through state and local workforce development systems.' 
    rssURL='&table_name=labor&agency=Employment%20and%20Training%20Administration' 
    feedName='Employment and Training Administration' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/employment-standards' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Employment Standards Administration' 
    officialURL='https://www.dol.gov/agencies/esa' 
    missionStatement='ESA enforces federal laws related to wages, hours, child labor, and family and medical leave, and ensures protection for certain immigrant workers.' 
    rssURL='&table_name=labor&agency=Employment%20Standards%20Administration' 
    feedName='Employment Standards Administration' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/contract-compliance' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Federal Contract Compliance Programs Office' 
    officialURL='https://www.dol.gov/agencies/ofccp' 
    missionStatement='OFCCP ensures that employers doing business with the federal government comply with the laws and regulations requiring nondiscrimination.' 
    rssURL='&table_name=labor&agency=Federal%20Contract%20Compliance%20Programs%20Office' 
    feedName='Federal Contract Compliance Programs Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/labor-statistics' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Labor Statistics Bureau' 
    officialURL='https://www.bls.gov/' 
    missionStatement='BLS is the principal fact-finding agency for the federal government in the broad field of labor economics and statistics.' 
    rssURL='&table_name=labor&agency=Labor%20Statistics%20Bureau' 
    feedName='Labor Statistics Bureau' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/labor-management-standards' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Labor-Management Standards Office' 
    officialURL='https://www.dol.gov/agencies/olms' 
    missionStatement='OLMS administers and enforces provisions of the Labor-Management Reporting and Disclosure Act of 1959.' 
    rssURL='&table_name=labor&agency=Labor-Management%20Standards%20Office' 
    feedName='Labor-Management Standards Office' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/mine-safety' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Mine Safety and Health Administration' 
    officialURL='https://www.msha.gov/' 
    missionStatement='MSHA works to prevent death, illness, and injury from mining and promote safe and healthful workplaces for U.S. miners.' 
    rssURL='&table_name=labor&agency=Mine%20Safety%20and%20Health%20Administration' 
    feedName='Mine Safety and Health Administration' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/osha' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Occupational Safety and Health Administration' 
    officialURL='https://www.osha.gov/' 
    missionStatement='OSHA assures safe and healthful working conditions for working men and women by setting and enforcing standards and by providing training, outreach, education, and assistance.' 
    rssURL='&table_name=labor&agency=Occupational%20Safety%20and%20Health%20Administration' 
    feedName='Occupational Safety and Health Administration' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/pension-welfare' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Pension and Welfare Benefits Administration' 
    officialURL='https://www.dol.gov/agencies/ebsa/about-ebsa/our-history' 
    missionStatement='PWBA is responsible for administering and enforcing the provisions of Title I of the Employee Retirement Income Security Act of 1974 (ERISA).' 
    rssURL='&table_name=labor&agency=Pension%20and%20Welfare%20Benefits%20Administration' 
    feedName='Pension and Welfare Benefits Administration' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/veterans-employment' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Veterans Employment and Training Service' 
    officialURL='https://www.dol.gov/agencies/vets' 
    missionStatement='VETS prepares America’s veterans, service members, and their spouses for meaningful careers, provides them with employment resources and expertise, protects their employment rights, and promotes their employment opportunities.' 
    rssURL='&table_name=labor&agency=Veterans%20Employment%20and%20Training%20Service' 
    feedName='Veterans Employment and Training Service' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/wage-hour' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Wage and Hour Division' 
    officialURL='https://www.dol.gov/agencies/whd' 
    missionStatement='WHD enforces federal labor laws including the minimum wage, overtime pay, recordkeeping, and child labor requirements of the Fair Labor Standards Act.' 
    rssURL='&table_name=labor&agency=Wage%20and%20Hour%20Division' 
    feedName='Wage and Hour Division' 
    topic='Economy' 
  />
)} />

<Route exact path='/us/workers-compensation' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfLabor.png' 
    agencyName='Workers Compensation Programs Office' 
    officialURL='https://www.dol.gov/agencies/owcp' 
    missionStatement='OWCP administers four major disability compensation programs which provide wage replacement benefits, medical treatment, vocational rehabilitation, and other benefits to certain workers or their dependents who experience work-related injury or occupational disease.' 
    rssURL='&table_name=labor&agency=Workers%20Compensation%20Programs%20Office' 
    feedName='Workers Compensation Programs Office' 
    topic='Economy' 
  />
)} />

    {/*Department of the Interior */}
    <Route exact path='/us/fish&wildlife' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfInterior.png' 
            agencyName='Fish and Wildlife Service' 
            officialURL='http://www.fws.gov/' 
            missionStatement='The United States Fish and Wildlife Service is the principal Federal agency dedicated to fish and wildlife conservation..' 
            rssURL='&table_name=interior&agency=Fish%20and%20Wildlife%20Service' 
            feedName='Fish and Wildlife Service' 
            topic='Environment' 
          />
        )} />

<Route exact path='/us/geological-survey' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Geological Survey' 
    officialURL='https://www.usgs.gov/' 
    missionStatement='The USGS serves the Nation by providing reliable scientific information to describe and understand the Earth.' 
    rssURL='&table_name=interior&agency=Geological%20Survey' 
    feedName='Geological Survey' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/hearings-appeals' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Hearings and Appeals Office, Interior Department' 
    officialURL='https://www.doi.gov/oha' 
    missionStatement='The Office of Hearings and Appeals provides an impartial forum for adjudicating disputes involving the Department of the Interior.' 
    rssURL='&table_name=interior&agency=Hearings%20and%20Appeals%20Office%2C%20Interior%20Department' 
    feedName='Hearings and Appeals Office, Interior Department' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/indian-affairs' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Indian Affairs Bureau' 
    officialURL='https://www.bia.gov/' 
    missionStatement='The Bureau of Indian Affairs provides services to approximately 1.9 million American Indians and Alaska Natives.' 
    rssURL='&table_name=interior&agency=Indian%20Affairs%20Bureau' 
    feedName='Indian Affairs Bureau' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/indian-trust-transition' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Indian Trust Transition Office' 
    officialURL='https://www.doi.gov/ost/about/itto' 
    missionStatement='The Indian Trust Transition Office manages the transition of trust services from the Bureau of Indian Affairs to the Office of the Special Trustee for American Indians.' 
    rssURL='&table_name=interior&agency=Indian%20Trust%20Transition%20Office' 
    feedName='Indian Trust Transition Office' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/land-management' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Land Management Bureau' 
    officialURL='https://www.blm.gov/' 
    missionStatement='The Bureau of Land Management sustains the health, diversity, and productivity of public lands for the use and enjoyment of present and future generations.' 
    rssURL='&table_name=interior&agency=Land%20Management%20Bureau' 
    feedName='Land Management Bureau' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/minerals-management' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Minerals Management Service' 
    officialURL='https://www.boem.gov/' 
    missionStatement='The Minerals Management Service manages the nation’s natural gas, oil and other mineral resources on the outer continental shelf.' 
    rssURL='&table_name=interior&agency=Minerals%20Management%20Service' 
    feedName='Minerals Management Service' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/mines-bureau' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Mines Bureau' 
    officialURL='https://www.doi.gov/ocl/oms' 
    missionStatement='The Bureau of Mines studies the extraction, processing, use, and conservation of mineral resources.' 
    rssURL='&table_name=interior&agency=Mines%20Bureau' 
    feedName='Mines Bureau' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/national-biological' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='National Biological Service' 
    officialURL='https://www.doi.gov/bureau-offices/national-biological-service' 
    missionStatement='The National Biological Service provides scientific information needed to support the sound management and conservation of our Nation’s biological resources.' 
    rssURL='&table_name=interior&agency=National%20Biological%20Service' 
    feedName='National Biological Service' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/nccc' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='National Civilian Community Corps' 
    officialURL='https://www.nationalservice.gov/programs/americorps/americorps-nccc' 
    missionStatement='NCCC strengthens communities and develops leaders through direct, team-based national and community service.' 
    rssURL='&table_name=interior&agency=National%20Civilian%20Community%20Corps' 
    feedName='National Civilian Community Corps' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/indian-gaming' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='National Indian Gaming Commission' 
    officialURL='https://www.nigc.gov/' 
    missionStatement='The National Indian Gaming Commission is committed to the integrity of the Indian gaming industry.' 
    rssURL='&table_name=interior&agency=National%20Indian%20Gaming%20Commission' 
    feedName='National Indian Gaming Commission' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/national-park-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='National Park Service' 
    officialURL='https://www.nps.gov/' 
    missionStatement='The National Park Service preserves unimpaired the natural and cultural resources and values of the National Park System for the enjoyment, education, and inspiration of this and future generations.' 
    rssURL='&table_name=interior&agency=National%20Park%20Service' 
    feedName='National Park Service' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/natural-resources-revenue' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Natural Resources Revenue Office' 
    officialURL='https://www.onrr.gov/' 
    missionStatement='ONRR collects, accounts for, and disburses revenues from energy and mineral leases on the Outer Continental Shelf and onshore federal and American Indian lands.' 
    rssURL='&table_name=interior&agency=Natural%20Resources%20Revenue%20Office' 
    feedName='Natural Resources Revenue Office' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/ocean-energy-management' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Ocean Energy Management Bureau' 
    officialURL='https://www.boem.gov/' 
    missionStatement='BOEM manages the exploration and development of the nation’s offshore resources.' 
    rssURL='&table_name=interior&agency=Ocean%20Energy%20Management%20Bureau' 
    feedName='Ocean Energy Management Bureau' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/ocean-energy-enforcement' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Ocean Energy Management, Regulation, and Enforcement Bureau' 
    officialURL='https://www.boemre.gov/' 
    missionStatement='BOEMRE enforces safety and environmental regulations governing the offshore oil and gas industry.' 
    rssURL='&table_name=interior&agency=Ocean%20Energy%20Management%2C%20Regulation%2C%20and%20Enforcement%20Bureau' 
    feedName='Ocean Energy Management, Regulation, and Enforcement Bureau' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/reclamation' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Reclamation Bureau' 
    officialURL='https://www.usbr.gov/' 
    missionStatement='The Bureau of Reclamation oversees water resource management, specifically the oversight and operation of the diversion, delivery, and storage projects that it has built throughout the western United States for irrigation, water supply, and hydroelectric power.' 
    rssURL='&table_name=interior&agency=Reclamation%20Bureau' 
    feedName='Reclamation Bureau' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/safety-environmental-enforcement' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Safety and Environmental Enforcement Bureau' 
    officialURL='https://www.bsee.gov/' 
    missionStatement='BSEE promotes safety, protects the environment, and conserves resources offshore through vigorous regulatory oversight and enforcement.' 
    rssURL='&table_name=interior&agency=Safety%20and%20Environmental%20Enforcement%20Bureau' 
    feedName='Safety and Environmental Enforcement Bureau' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/special-trustee-american-indians' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Special Trustee for American Indians Office' 
    officialURL='https://www.doi.gov/ost' 
    missionStatement='OST is responsible for financial asset management of tribal trust funds, improving the accountability and management of Indian funds held in trust by the federal government.' 
    rssURL='&table_name=interior&agency=Special%20Trustee%20for%20American%20Indians%20Office' 
    feedName='Special Trustee for American Indians Office' 
    topic='Environment' 
  />
)} />

<Route exact path='/us/surface-mining' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfInterior.png' 
    agencyName='Surface Mining Reclamation and Enforcement Office' 
    officialURL='https://www.osmre.gov/' 
    missionStatement='OSMRE ensures that coal mines operate in a manner that protects citizens and the environment during mining, and that the land is restored to beneficial use after mining.' 
    rssURL='&table_name=interior&agency=Surface%20Mining%20Reclamation%20and%20Enforcement%20Office' 
    feedName='Surface Mining Reclamation and Enforcement Office' 
    topic='Environment' 
  />
)} />


 {/*Department of Homland */}
 <Route exact path='/us/coastguard' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfHomeland.png' 
            agencyName='Coast Guard' 
            officialURL='http://www.uscg.mil/' 
            missionStatement='The Coast Guard protects the public, the environment, and U.S. economic interests in the Nations ports and waterways, along the coast, on international waters, or in any maritime region, as required, to support national security. ' 
            rssURL='&table_name=homeland_security&agency=Coast%20Guard' 
            feedName='Coast Guard' 
            topic='Defense' 
          />
        )} />

<Route exact path='/us/fema' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='Federal Emergency Management Agency' 
    officialURL='https://www.fema.gov/' 
    missionStatement='FEMA helps people before, during, and after disasters.' 
    rssURL='&table_name=homeland_security&agency=Federal%20Emergency%20Management%20Agency' 
    feedName='Federal Emergency Management Agency' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/fletc' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='Federal Law Enforcement Training Center' 
    officialURL='https://www.fletc.gov/' 
    missionStatement='FLETC provides career-long training to law enforcement professionals to help them fulfill their responsibilities safely and proficiently.' 
    rssURL='&table_name=homeland_security&agency=Federal%20Law%20Enforcement%20Training%20Center' 
    feedName='Federal Law Enforcement Training Center' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/national-communications' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='National Communications System' 
    officialURL='https://www.dhs.gov/cisa/national-communications-system' 
    missionStatement='The National Communications System coordinates the planning, preparedness, and provisioning of national security and emergency preparedness communications for the federal government.' 
    rssURL='&table_name=homeland_security&agency=National%20Communications%20System' 
    feedName='National Communications System' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/secret-service' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='Secret Service' 
    officialURL='https://www.secretservice.gov/' 
    missionStatement='The Secret Service safeguards the nation’s financial infrastructure and payment systems to preserve the integrity of the economy, and it protects national leaders, visiting heads of state and government, designated sites, and National Special Security Events.' 
    rssURL='&table_name=homeland_security&agency=Secret%20Service' 
    feedName='Secret Service' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/tsa' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='Transportation Security Administration' 
    officialURL='https://www.tsa.gov/' 
    missionStatement='TSA protects the nation’s transportation systems to ensure freedom of movement for people and commerce.' 
    rssURL='&table_name=homeland_security&agency=Transportation%20Security%20Administration' 
    feedName='Transportation Security Administration' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/uscis' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='U.S. Citizenship and Immigration Services' 
    officialURL='https://www.uscis.gov/' 
    missionStatement='USCIS administers the nation’s lawful immigration system, safeguarding its integrity and promise by efficiently and fairly adjudicating requests for immigration benefits while protecting Americans, securing the homeland, and honoring our values.' 
    rssURL='&table_name=homeland_security&agency=U.S.%20Citizenship%20and%20Immigration%20Services' 
    feedName='U.S. Citizenship and Immigration Services' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/cbp' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='U.S. Customs and Border Protection' 
    officialURL='https://www.cbp.gov/' 
    missionStatement='CBP is one of the world’s largest law enforcement organizations and is charged with keeping terrorists and their weapons out of the U.S. while facilitating lawful international travel and trade.' 
    rssURL='&table_name=homeland_security&agency=U.S.%20Customs%20and%20Border%20Protection' 
    feedName='U.S. Customs and Border Protection' 
    topic='Defense' 
  />
)} />

<Route exact path='/us/ice' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfHomeland.png' 
    agencyName='U.S. Immigration and Customs Enforcement' 
    officialURL='https://www.ice.gov/' 
    missionStatement='ICE’s mission is to protect America from the cross-border crime and illegal immigration that threaten national security and public safety.' 
    rssURL='&table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement' 
    feedName='U.S. Immigration and Customs Enforcement' 
    topic='Defense' 
  />
)} />


{/*Department of Justice */}
<Route exact path='/us/atf' render={(props) => (
          <AgencyTemplate 
            {...props} 
            imageName='/departmentOfJustice.png' 
            agencyName='Alcohol, Tobacco, Firearms, and Explosives Bureau' 
            officialURL='http://www.atf.gov/' 
            missionStatement='The Bureau of Alcohol, Tobacco, Firearms and Explosives (ATF) is responsible for enforcing Federal criminal laws and regulating the firearms and explosives industries.' 
            rssURL='&table_name=justice&agency=Alcohol%20Tobacco%20Firearms%20and%20Explosives%20Bureau' 
            feedName='Alcohol, Tobacco, Firearms, and Explosives Bureau' 
            topic='Justice' 
          />
        )} />

<Route exact path='/us/antitrust-division' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Antitrust Division' 
    officialURL='https://www.justice.gov/atr' 
    missionStatement='The Antitrust Division promotes economic competition through enforcing and providing guidance on antitrust laws and principles.' 
    rssURL='&table_name=justice&agency=Antitrust%20Division' 
    feedName='Antitrust Division' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/dea' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Drug Enforcement Administration' 
    officialURL='https://www.dea.gov/' 
    missionStatement='The DEA enforces the controlled substances laws and regulations of the United States and brings to justice organizations involved in the growing, manufacture, or distribution of controlled substances.' 
    rssURL='&table_name=justice&agency=Drug%20Enforcement%20Administration' 
    feedName='Drug Enforcement Administration' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/immigration-review' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Executive Office for Immigration Review' 
    officialURL='https://www.justice.gov/eoir' 
    missionStatement='EOIR administers the nation’s immigration court system, providing interpretation and application of immigration laws.' 
    rssURL='&table_name=justice&agency=Executive%20Office%20for%20Immigration%20Review' 
    feedName='Executive Office for Immigration Review' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/fbi' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Federal Bureau of Investigation' 
    officialURL='https://www.fbi.gov/' 
    missionStatement='The FBI is an intelligence-driven and threat-focused national security organization with both intelligence and law enforcement responsibilities.' 
    rssURL='&table_name=justice&agency=Federal%20Bureau%20of%20Investigation' 
    feedName='Federal Bureau of Investigation' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/federal-prison-industries' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Federal Prison Industries' 
    officialURL='https://www.unicor.gov/' 
    missionStatement='Federal Prison Industries, also known as UNICOR, is a government corporation that employs inmates in federal prisons.' 
    rssURL='&table_name=justice&agency=Federal%20Prison%20Industries' 
    feedName='Federal Prison Industries' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/foreign-claims' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Foreign Claims Settlement Commission' 
    officialURL='https://www.justice.gov/fcsc' 
    missionStatement='The Foreign Claims Settlement Commission adjudicates claims of U.S. nationals against foreign governments.' 
    rssURL='&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission' 
    feedName='Foreign Claims Settlement Commission' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/immigration-naturalization' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Immigration and Naturalization Service' 
    officialURL='https://www.uscis.gov/history-and-genealogy/our-history/overview-ins-history' 
    missionStatement='The INS administered federal immigration laws concerning the admission, exclusion, deportation, and naturalization of aliens.' 
    rssURL='&table_name=justice&agency=Immigration%20and%20Naturalization%20Service' 
    feedName='Immigration and Naturalization Service' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/justice-programs' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Justice Programs Office' 
    officialURL='https://www.ojp.gov/' 
    missionStatement='The Office of Justice Programs provides innovative leadership to federal, state, local, and tribal justice systems by disseminating state-of-the-art knowledge and practices.' 
    rssURL='&table_name=justice&agency=Justice%20Programs%20Office' 
    feedName='Justice Programs Office' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/juvenile-justice' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Juvenile Justice and Delinquency Prevention Office' 
    officialURL='https://ojjdp.ojp.gov/' 
    missionStatement='The Office of Juvenile Justice and Delinquency Prevention provides national leadership, coordination, and resources to prevent and respond to juvenile delinquency and victimization.' 
    rssURL='&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office' 
    feedName='Juvenile Justice and Delinquency Prevention Office' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/national-institute-corrections' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='National Institute of Corrections' 
    officialURL='https://nicic.gov/' 
    missionStatement='NIC provides training, technical assistance, information services, and policy/program development assistance to federal, state, and local corrections agencies.' 
    rssURL='&table_name=justice&agency=National%20Institute%20of%20Corrections' 
    feedName='National Institute of Corrections' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/national-institute-justice' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='National Institute of Justice' 
    officialURL='https://nij.ojp.gov/' 
    missionStatement='NIJ is the research, development, and evaluation agency of the U.S. Department of Justice.' 
    rssURL='&table_name=justice&agency=National%20Institute%20of%20Justice' 
    feedName='National Institute of Justice' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/parole-commission' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Parole Commission' 
    officialURL='https://www.justice.gov/uspc' 
    missionStatement='The United States Parole Commission grants parole to federal and D.C. Code prisoners and establishes the conditions of parole and supervision.' 
    rssURL='&table_name=justice&agency=Parole%20Commission' 
    feedName='Parole Commission' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/prisons-bureau' render={(props) => (
  <AgencyTemplate 
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='Prisons Bureau' 
    officialURL='https://www.bop.gov/' 
    missionStatement='The Bureau of Prisons protects public safety by ensuring that federal offenders serve their sentences in facilities that are safe, humane, cost-efficient, and appropriately secure.' 
    rssURL='&table_name=justice&agency=Prisons%20Bureau' 
    feedName='Prisons Bureau' 
    topic='Justice' 
  />
)} />

<Route exact path='/us/marshals-service' render={(props) => (
  <AgencyTemplate
    {...props} 
    imageName='/departmentOfJustice.png' 
    agencyName='United States Marshals Service' 
    officialURL='https://www.usmarshals.gov/' 
    missionStatement='The U.S. Marshals Service is the nation’s oldest and most versatile federal law enforcement agency, serving as the enforcement arm of the federal courts.' 
    rssURL='&table_name=justice&agency=United%20States%20Marshals%20Service' 
    feedName='United States Marshals Service' 
    topic='Justice' 
  />
)} />
        </Switch>
      </Router>





  
    </>
  );







}


export default App;
