import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Spinner, Button, Modal, Form, Row, Image, Toast, Navbar } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, ShareFill, Clipboard, ClipboardCheck } from 'react-bootstrap-icons'; 
import './App.css';
import DesktopNavbar from './DesktopNavbar';
import useHandleContainerClick from './UseHandleContainerClick';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';

require('dotenv').config();

const apiKey = process.env.REACT_APP_API_KEY;

var storedTitle = '';
var storedDetailsLink = '';
var storedDocLink = '';
var storedDate = '';
var storedNote = '';
var storedFeedName = '';
var storedBranchName = '';
var storedTopicName = '';
var storedDescription = '';
var collectionCounter = localStorage.getItem('collectionNumber');

// Save the selected feed item data
function SaveFeedItem(
  saveCollectionTitle,
  saveCollectionDetailLink,
  saveCollectionDocLink,
  saveCollectionDate,
  saveCollectionNote,
  saveCollectionFeedName,
  saveCollectionBranchName,
  saveCollectionTopicName,
  saveCollectionDescription
) {
  storedTitle = saveCollectionTitle;
  storedDetailsLink = saveCollectionDetailLink;
  storedDocLink = saveCollectionDocLink;
  storedDate = saveCollectionDate;
  storedNote = saveCollectionNote;
  storedFeedName = saveCollectionFeedName;
  storedBranchName = saveCollectionBranchName;
  storedTopicName = saveCollectionTopicName;
  storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();

  return `${month}-${day}-${year}`;
}

function FeedDetailsPage() {
  const { schema, table, id } = useParams(); // Extract URL parameters
  const [data, setData] = useState(null);
  const [membersData, setMembersData] = useState([]); // Store member data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [note, setNote] = useState(''); // State to hold the note input
  const [modalIsOpen, setModalIsOpen] = useState(false); // For sponsor modal
  const [showToast, setShowToast] = useState(false); // For share button toast notification
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 }); // Toast position for share button
  const handleContainerClick = useHandleContainerClick();
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
  const [shareLink, setShareLink] = useState(''); // Share link
  const [linkCopied, setLinkCopied] = useState(false); // Track if link is copied

  // Fetch sponsor (members) data
  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const response = await fetch('https://api.govglance.org/members/?current_member=true&order_by=state&limit=1000&skip=0', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + apiKey,
          },
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const members = await response.json();
        setMembersData(members); // Store the fetched members data
      } catch (error) {
        console.error('Error fetching members data:', error);
      }
    };

    fetchMembersData();
  }, []);

  useEffect(() => {
    const url = `https://api.govglance.org/posts/recent?limit=1&skip=0&schema=${schema}&table=${table}&order_by=created_at&filter_column=id&filter_string=${id}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setData(data); // Set the detailed data for the selected item
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [schema, table, id]);

  // Save note function
  function saveNote(
    saveCollectionTitle,
    saveCollectionDetailLink,
    saveCollectionDocLink,
    saveCollectionDate,
    saveCollectionFeedName,
    saveCollectionBranchName,
    saveCollectionTopicName,
    saveCollectionDescription
  ) {
    collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter = collectionCounter ? parseInt(collectionCounter) + 1 : 1;

    localStorage.setItem('collectionNumber', collectionCounter);
    localStorage.setItem(`title${collectionCounter}`, saveCollectionTitle);
    localStorage.setItem(`detailLink${collectionCounter}`, saveCollectionDetailLink);
    localStorage.setItem(`docLink${collectionCounter}`, saveCollectionDocLink);
    localStorage.setItem(`itemNote${collectionCounter}`, note);
    localStorage.setItem(`itemDate${collectionCounter}`, saveCollectionDate);
    localStorage.setItem(`feedName${collectionCounter}`, saveCollectionFeedName);
    localStorage.setItem(`branchName${collectionCounter}`, saveCollectionBranchName);
    localStorage.setItem(`topicName${collectionCounter}`, saveCollectionTopicName);
    localStorage.setItem(`itemDescription${collectionCounter}`, saveCollectionDescription);

    setCollectionModalIsOpen(false);
    setNote(''); // Clear the note input
  }

  // Handle Share Button click and show toast notification
  const handleShareClick = (event) => {
    const shareUrl = `${window.location.origin}/feed/${schema}/${table}/${id}`;
    setShareLink(shareUrl); // Set share link for modal
    setShareModalIsOpen(true); // Open share modal
  };

  // Function to copy the share link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
    });
  };

  const renderData = () => {
    if (loading) {
      return (
        <div id="homebg">
          <Spinner animation="grow" variant="light" />
        </div>
      );
    }

    if (error) {
      return (
        <div id="homebg">
          <p>Error fetching data: {error.message}</p>
        </div>
      );
    }

    if (!data || data.length === 0) {
      return (
        <div id="fullBillFeed">
          <p>No results found.</p>
        </div>
      );
    }

    return data.map((item, index) => {
      const titleToDisplay = item.title || item.status_title;
      const link = item.url;
      const docLink = item.document_link || item.download?.pdfLink;
      const date = formatDate(item.created_at);
      const { committees, bill_status_data } = item; // Extract committees and bill_status_data

      // Bill status without text cap
      const latestBillAction = bill_status_data?.bill_latest_action_description || 'No bill status available';

      return (
        <>
          <div key={index} id="feedPageFeedNormalView" className="d-flex align-items-center justify-content-center">
            <Container id="feedContainerNormalView">
              {/* Title */}
              <Card.Title
                id="billText"
                onClick={(e) => {
                  e.stopPropagation(); 
                  const linkToOpen = docLink || link; 
                  window.open(linkToOpen, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >
                {titleToDisplay}
              </Card.Title>

              {/* Date */}
              <Card.Body id="billBodyText">Date: {date}</Card.Body>

              {/* Description */}
              <Card.Body id="billBodyText">{item.summary}</Card.Body>
    
              {/* Committees */}
              {committees && committees.length > 0 && (
                <div id="committeesSection" className="mt-3">
                  <Card.Title id="billText">Committees:</Card.Title>
                  {committees.map((committee, index) => (
                    <Card.Body id="billBodyText" key={index}>
                      {committee.committeeName} ({committee.chamber})
                    </Card.Body>
                  ))}
                </div>
              )}

              {/* Bill Status */}
              <div id="billActionSection" className="mt-3">
                <Card.Title id="billText">Latest Bill Action:</Card.Title>
                <Card.Body id="billBodyText">{latestBillAction}</Card.Body>
              </div>
    
              {/* Buttons */}
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                  id="billLink"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    window.open(link, '_blank');
                  }}
                >
                  <InfoCircle /> Details
                </Button>

                {docLink && (
                  <Button
                    id="viewBillButton"
                    onClick={(e) => {
                      e.stopPropagation(); 
                      window.open(docLink, '_blank');
                    }}
                  >
                    <FileText /> Document
                  </Button>
                )}

                <Button
                  id="addToCollectionButton"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    setCollectionModalIsOpen(true);
                    SaveFeedItem(titleToDisplay, link, docLink, date, '', schema, table, item.summary);
                  }}
                >
                  <FolderPlus /> 
                </Button>

                <Button
                  id="shareButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShareClick(e); // Handle share button
                  }}
                >
                      <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                </svg>
                </Button>
              </div>

        {/* Sponsors and Cosponsors Section */}
        {item.members && item.members.length > 0 && (
  <div id="sponsorsSection" className="mt-3">
    <Card.Title id="billText">Sponsors and Cosponsors:</Card.Title>
    <br></br>
    <div className="sponsors-list">
      {item.members.map((member, index) => {
        const matchedMember = membersData.find(
          (m) => m.bio_guide_id === member.bioGuideId
        );

        if (!matchedMember) {
          console.log('No match found for:', member.bioGuideId);
          return null;
        }

        const imageUrl = matchedMember.depication?.imageUrl || 'path/to/default/image.jpg';
        const firstName = matchedMember.first_name || '';
        const lastName = matchedMember.last_name || '';
        const party = member.party;
        const state = member.state;

        return (
          <div key={index} className="sponsorItem" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '20px' }}onClick={() => {
            handleContainerClick(
              matchedMember.bio_guide_id,
              `${firstName} ${lastName}`,
              matchedMember.official_website_url || '',
              imageUrl,
              matchedMember.terms?.[matchedMember.terms.length - 1]?.chamber || '',
              matchedMember.terms?.[matchedMember.terms.length - 1]?.startYear || '',
              matchedMember.terms?.[matchedMember.terms.length - 1]?.stateName || '',
              matchedMember.party_history[0] || {},
              matchedMember.address_information || {}
            );
          }}>
            <div id='profileImage'>
              <Image id='profileImage-image'
                src={imageUrl}
                alt={`${firstName} ${lastName}`}
                style={{ marginRight: '20px' }} // Increased space between image and text
              />
            </div>
            <p style={{ marginBottom: '0', color: 'white', display: 'flex', alignItems: 'center' }}>
              <strong>{firstName} {lastName}</strong> ({party}-{state})
            </p>
          </div>
        );
      })}
         <div className="d-flex justify-content-center align-items-center mt-3">
      <Button id="feedViewMoreButton" onClick={() => window.location.href = `/feed/${schema}/${table}`}>
        Back
      </Button>
    </div>
    </div>
    
  </div>
)}

            </Container>
          </div>
        </>
      );
    });
  };

  return (
    <>
    <BrowserView>
    <div id='homebg'>
      <DesktopNavbar/>

      {renderData()}

      {/* Toast Notification for "Copied to Clipboard" */}
      <Toast 
        style={{ position: 'absolute', top: toastPosition.top, left: toastPosition.left, zIndex: 1000, backgroundColor: '#002031' }} 
        onClose={() => setShowToast(false)} 
        show={showToast} 
        delay={3000} 
        autohide
      >
        <Toast.Body id='billBodyText'>Copied to clipboard!</Toast.Body>
      </Toast>

      <Modal
        id="fullBillModal"
        show={collectionModalIsOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setCollectionModalIsOpen(false)}
      >
        <Modal.Header id="billModalHeader" closeButton>
          <h3>{storedTitle}</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          {storedDescription}
          {storedDate}
          <div id="fullBill">
            <Form id="noteModalForm">
              <Form.Group id="noteModalForm" className="mb-3">
                <Form.Control
                  id="noteModalFeedItem"
                  placeholder="Add a note here"
                  type="text"
                  as="textarea"
                  rows={2}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <br />
              </Form.Group>
            </Form>

            <Modal.Footer id="addNoteModalFooter">
              <Button
                variant="primary"
                id="noteSaveToCollectionButton"
                onClick={() => saveNote(storedTitle, storedDetailsLink, storedDocLink, storedDate, schema, table, storedTopicName, storedDescription)}
              >
                Save To Collection
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>

      {/* Share Modal */}
      <Modal
        id="shareModal"
        show={shareModalIsOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShareModalIsOpen(false)}
      >
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control
              style={{
                backgroundColor: 'transparent',
                color: 'white',
                outline: 'none',
              }}
              id="noteModalFeedItem"
              type="text"
              readOnly
              value={shareLink}
            />
          </Form.Group>
          <Modal.Footer id="shareFooter">
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
    </BrowserView>

    <MobileView>
    <div id='homebg'>
      <MobileTopNavbar/>

      {renderData()}

      {/* Toast Notification for "Copied to Clipboard" */}
      <Toast 
        style={{ position: 'absolute', top: toastPosition.top, left: toastPosition.left, zIndex: 1000, backgroundColor: '#002031' }} 
        onClose={() => setShowToast(false)} 
        show={showToast} 
        delay={3000} 
        autohide
      >
        <Toast.Body id='billBodyText'>Copied to clipboard!</Toast.Body>
      </Toast>

      <Modal
        id="fullBillModal"
        show={collectionModalIsOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setCollectionModalIsOpen(false)}
      >
        <Modal.Header id="billModalHeader" closeButton>
          <h3>{storedTitle}</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          {storedDescription}
          {storedDate}
          <div id="fullBill">
            <Form id="noteModalForm">
              <Form.Group id="noteModalForm" className="mb-3">
                <Form.Control
                  id="noteModalFeedItem"
                  placeholder="Add a note here"
                  type="text"
                  as="textarea"
                  rows={2}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <br />
              </Form.Group>
            </Form>

            <Modal.Footer id="addNoteModalFooter">
              <Button
                variant="primary"
                id="noteSaveToCollectionButton"
                onClick={() => saveNote(storedTitle, storedDetailsLink, storedDocLink, storedDate, schema, table, storedTopicName, storedDescription)}
              >
                Save To Collection
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>

      {/* Share Modal */}
      <Modal
        id="shareModal"
        show={shareModalIsOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShareModalIsOpen(false)}
      >
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control
              style={{
                backgroundColor: 'transparent',
                color: 'white',
                outline: 'none',
              }}
              id="noteModalFeedItem"
              type="text"
              readOnly
              value={shareLink}
            />
          </Form.Group>
          <Modal.Footer id="shareFooter">
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
           
            </div>
            </Navbar>
    </div>
    </MobileView>
    </>
  );
}

export default FeedDetailsPage;
