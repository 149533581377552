import React, { useState } from 'react';
import { Image, Navbar, Dropdown, Button, Col, Modal, Nav, Row, Container } from 'react-bootstrap';
import { Link45deg, HeartFill } from 'react-bootstrap-icons';
import MobileOffCanvas from './MobileOffCanvas';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import MobileTopNavbar from './MobileTopNavbar';
import GenerateFeed from './GenerateFeed';
import { CreateFeedAPI, CreateFeedAPIDepartmentUS } from './Functions';
import DesktopNavbar from './DesktopNavbar';
import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import FederalReservePageMobile from './FederalReservePageMobile';


const FederalReservePage = () => {
  const imageName = '/federalReserve.png'; // image path
  const agencyName = 'Federal Reserve'; // agency name
  const officialURL = 'https://www.federalreserve.gov/'; // official URL
  const missionStatement = 'The Federal Reserve System is the central bank of the United States. It performs five general functions to promote the effective operation of the U.S. economy and, more generally, the public interest'; // mission statement
  const rssURL = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Speeches%20%26%20Testimony'; // RSS feed URL
  const feedName = 'Speeches & Testimony'; // feed name
  const topic = 'Economy'; // topic name

  const rssURL2 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Research%20Paper%20Feeds'; // RSS feed URL
  const feedName2 = 'Research Paper'; // feed name

  const rssURL3 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Other'; // RSS feed URL
  const feedName3 = 'Others'; // feed name

  const rssURL4 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Press%20Releases'; // RSS feed URL
  const feedName4 = 'Press Releases'; // feed name


  const rssURL5 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Research%20Paper%20Feeds'; // RSS feed URL
  const feedName5 = 'Research Paper'; // feed name


  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false);
  const [papersModalIsOpen, setPapersModalIsOpen] = useState(false);
  const [announcementsModalIsOpen, setAnnouncementsModalIsOpen] = useState(false);
  const [othersModalIsOpen, setOthersModalIsOpen] = useState(false);
  return (
    <>
    <BrowserView>
      <DesktopNavbar/>
      <div id='homebg'>
      <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>
   {imageName && (
     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src={imageName} />
   </div>
   )}

   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3 >Federal Reserve</h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span>{missionStatement}</span>
<br></br>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>
{officialURL && (
        <Link  style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open(officialURL, "_blank");
        }}>
          {officialURL}
          </Link>
)}
</div>
  </div>

     </div>
     </div>


     </div>
     <br></br>
     <br></br>
     <br></br>
     {/* <Card.Body  id='cardBodyRep' ><h3>Documents</h3></Card.Body>

     <div  id='agency'></div>
 

  {CreateFeedAPIDepartmentUS(rssURL, 'agency', feedName, 'Executive', topic, 'Doc', GenerateFeedDepartment)} */}


              
                {/* <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                  <GenerateFeedDepartment   url={rssURL}
                                name={feedName} branch='Executive' topic={topic} buttonName='Doc'  />
                </Modal> */}
           </div>
        

           <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'  onClick={() => setAnnouncementsModalIsOpen(true)}><b>{feedName4}</b></h4>
                          <div id='agency4'></div>
          {CreateFeedAPI(rssURL4, 'agency4', feedName4, '', topic, 'Doc', GenerateFeed )}


          <Modal id='whiteHouseDocsModal' show={announcementsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAnnouncementsModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName4}</h3></Modal.Header>
          <GenerateFeed url={rssURL4} name={feedName4} branch='' topic={topic} buttonName='Doc' />
        </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setArmyModalIsOpen(true)}><b>{feedName}</b></h4>
                          <div id='agency'></div>
          {CreateFeedAPI(rssURL, 'agency', feedName, '', topic, 'Doc', GenerateFeed )}

          <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeed url={rssURL} name={feedName} branch='' topic={topic} buttonName='Doc' />
        </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

            

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'  onClick={() => setPapersModalIsOpen(true)}><b>{feedName2}</b></h4>
                          <div id='agency2'></div>
          {CreateFeedAPI(rssURL2, 'agency2', feedName2, '', topic, 'Doc', GenerateFeed )}

          <Modal id='whiteHouseDocsModal' show={papersModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPapersModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName2}</h3></Modal.Header>
          <GenerateFeed url={rssURL2} name={feedName2} branch='' topic={topic} buttonName='Doc' />
        </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setArmyModalIsOpen(true)}><b>{feedName3}</b></h4>
                          <div id='agency3'></div>
          {CreateFeedAPI(rssURL3, 'agency3', feedName3, '', topic, 'Doc', GenerateFeed )}

          <Modal id='whiteHouseDocsModal' show={othersModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOthersModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName3}</h3></Modal.Header>
          <GenerateFeed url={rssURL3} name={feedName3} branch='' topic={topic} buttonName='Doc' />
        </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>
   
                <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>

<Nav.Link  onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link  onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
   
      </div>
</BrowserView>

   
<MobileView>

<FederalReservePageMobile/>


</MobileView>
     

      
      
    </>
  )
}

export default FederalReservePage;
